import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import LoadingAnimation from "../components/LoadingAnimation";
import HeaderFixed from "../components/HeaderFixed";
import Navfixed from "../components/Navfixed";
import PDFGenerator from "../components/PDFGenerator";


export default function DocumentsPage() {
  return (
    <div>
      <HeaderFixed pageTitle="DOCUMENTI" menuPage="DOCUMENTS" />
      <PDFGenerator />
      <Navfixed />
    </div>
  );
}
