import './LoadingAnimation.css'

export default function LoadingAnimation(){
  return(
    <div className="loading-wrapper">
      <div className="loading-box-wrap">
          <div className="loadingbox one"></div>
          <div className="loadingbox two"></div>
          <div className="loadingbox three"></div>
          <div className="loadingbox four"></div>
          <div className="loadingbox five"></div>
          <div className="loadingbox six"></div>
      </div>
    </div>
  )

}