import React, { Fragment} from "react";
import tw from "twin.macro";
import styled from "styled-components"
import { longInput, mainFucsia, mainPurple } from "../const";
import { Listbox, Transition } from "@headlessui/react";
import "./CSelector.css"
import {ChevronUpDownIcon} from "@heroicons/react/20/solid"

const SelectContainer = styled.div`
  ${tw`
      flex
      items-center
      text-white
      font-normal
      relative
      text-center
  `} 
  width: 100%;
  letter-spacing: 0.4px;
  font-size: 16px;
  height: 40px;
  border-bottom: 2px solid white;
  background: transparent;

  button {
    width: 100%;
  }

  
`

const ListboxContainer = styled.div`
  ${tw`
      flex
      flex-col
      text-white
      font-medium
  `}
  font-size: 15px;

  ${props => props.width ? `width: ${props.width};` : 'width: 150px;'}
  p{
    margin-bottom: 3px;
    margin-left: 5px;
  }
`




export default function CSelector(props){
  const {options, val, func, title, width} = props;

  return(
    <ListboxContainer width={width ? width : "150px"}>
      <p>{title}</p>
      <Listbox className="listbox" value={val} onChange={func}>
        <SelectContainer>
          <Listbox.Button className="listButton">
            <span className="valueSpan">{val}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 pt-0.5">
              <ChevronUpDownIcon
                className="h-4 w-4 text-white"
                aria-hidden="true"
              />
            </span>  
          </Listbox.Button>
          <Transition
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          ></Transition>
          <Listbox.Options className="listMenu absolute max-h-60 w-full overflow-auto rounded-md pt-1 text-base shadow-lg focus:outline-none opt">
            {options.map((option) => (
              <Listbox.Option value={option} className="listOption">
                {option}
              </Listbox.Option>
            ))}
          </Listbox.Options>
          </SelectContainer>
      </Listbox>
    </ListboxContainer>
  )
}
