import React from "react"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Pie } from "react-chartjs-2";
import styled from "styled-components"
import tw from "twin.macro";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const PageContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
      mt-5
  `}
`

const Container = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
`

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .doughnutTitle{
    color: #00dada;
    font-size: 18px;
  }

  .value{
    color: white;
    font-size: 24px;
  }

  .euro{
    opacity: 0.9;
    font-size: 0.9em;
    margin-left: 2px;
  }
  
  z-index: -1;
`


export default function DoughnutChart(props){
  const colors = ['#33ccff', '#6600cc', '#967bb6','#708090','#aa0000' ,'#008080'];
  const {data, title} = props;

  var bColors = [];

  for (let i = 0; i < data.data.length; i++){
    bColors.push(colors[i]);
  }

  const dataP = {
    labels: data.labels,
    datasets: [
      {
        data: data.data,
        backgroundColor: bColors,
        borderWidth: 7,
        borderColor: '#181818',
        hoverOffset: 10,
        borderRadius: 50
      }
    ]
  };

  const options = {
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      }
    }
  };


  return(
    <PageContainer>
      <Container>
        <Doughnut
          data={dataP}
          options = {options}
        />
        <TextContainer>
          <span className="doughnutTitle">{title}</span>
          <span className="value"><b>{data.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</b><span className="euro">€</span></span>
        </TextContainer>
      </Container>
    </PageContainer>
  )
}