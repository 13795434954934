import React from "react";
import CSelector from "./CSelector"
import { shoeSize, mainFucsia, mainPurple, shoeSizes } from "../const";
import tw from "twin.macro";
import styled from "styled-components"
import check from "../icons/check.png"

const MegaContainer = styled.div`
  ${tw`
      flex
      flex-col
  `}
`

const Title = styled.div`
  ${tw`
      text-white
      font-medium

  `}
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 5px;
`

const FormContainer = styled.div`
  ${tw`
      my-6
      flex
      flex-row
      justify-between
      text-white
      items-center
      px-8
  `}
`

const FakeRadio = styled.div`
  ${tw`
      mr-2 
  `}
  width: 15px;
  height: 15px;
  background: transparent;
  border-width: 1px;
  border-color: ${mainPurple};
`

const RadioContainer = styled.div`
  ${tw`
      flex
      flex-row
      items-center
      text-white
      mr-2
  `}
  font-size: 14px;
`

const RadioActive = styled.div`
  ${tw`
      flex
      justify-center
      items-center
      mr-2
  `}
  width: 15px;
  height: 15px;
  background: linear-gradient(to right, ${mainPurple}, ${mainFucsia});

  img {
    width: 10px;
    height: 10px;
  }
`

const FlexRow = styled.div`
  ${tw`
      flex 
      flex-row
      justify-between
      items-center
  `}
`

export default function NewShoeForm(props){
  const {activeKey, setKey, select, currSize} = props;

  return(
    <MegaContainer>
        <Title>Seleziona taglia</Title>
        <FlexRow>
          <CSelector options={shoeSizes[activeKey]} val={currSize} func={select} />
          <CSelector options={Object.keys(shoeSizes)} val={activeKey} func={setKey}/>
        </FlexRow>
    </MegaContainer>
  )
}