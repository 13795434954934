import { gql, useMutation, useQuery } from "@apollo/client";
import {React, useState, useEffect, Fragment, useCallback, useRef} from "react";
import styled from "styled-components"
import tw from "twin.macro";
import ProgressBar from "@ramonak/react-progress-bar"
import './css_files/ObjectivesPage.css'
import { getGoalName, getGoalType, goalTypes, longInput, mainBackground, secondaryText } from "../const";
import MainButton from "../components/MainButton";
import { Dialog, Transition } from "@headlessui/react";
import FormField from "../components/FormField";
import CSelector from "../components/CSelector";
import ErrorPopUp from "../components/ErrorPopUp";
import CorrectPopUp from "../components/CorrectPopUp";
import Navfixed from "../components/Navfixed";
import HeaderFixed from "../components/HeaderFixed";
import LoadingAnimation from "../components/LoadingAnimation";
import deleteIcon from "../icons/delete.png"
import { useGlobalState, actionTypes } from "../GlobalStateContext";

const PageContainer = styled.div`
  padding-bottom: 20px;
`

const FormContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      py-7
      px-5
  `}
  border-radius: 20px;
  background: #242424;
  color: white;
  position: fixed;
  top: 15%;
`

const FormTitle = styled.div`
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
`

const RegistrationText = styled.span`
  ${tw`
      text-white
      flex
      w-full
      justify-center
  `}
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 35px;
`

const GoalContainer = styled.div`
  ${tw`
  
  `}
  color: white;
  margin-bottom: 50px;
  margin-top: 20px;
`

const GoalTitle = styled.div`
  ${tw`

  `}
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
  margin-left: 10px;
`

const Goals = styled.div`
  ${tw`
  `}
  background-color: #242424;
`

const BarText = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .goalName{
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }

  img{
    width: 18px;
    height: 18px;
  }

  .completato{
    position: absolute;
    right: 15px;
    font-size: 14px;
  }
`

const Goal = styled.div`
  ${tw`
    py-5
  `}
  position:relative;
  border-bottom: 2px solid #181818;
  padding-bottom: 10px;

  .barCompleted{
    background: #7affffaa;
    border: 2px solid #00dada;
    width: ${props => props.width ? props.width : '0%'};
    border-radius: 5px;
    height: 20px;
  }
`

const BarValue = styled.div`
  ${tw`
      flex
      items-center
  `}
  position: relative;
  padding-left: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;

  .percent{
    position: absolute;
    right: 15px;
    font-size: 20px;
    font-weight: 600;
  }
`

const BarGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`

const NumericValues = styled.div`
  ${tw`
      flex
      justify-between
      items-center
      w-full
      px-8
  `}

  margin-top: 5px;
  .goalValue{
    font-size: 14px;
    color: white;
  }

  .currentValue{
    font-size: 14px;
    color: #00dada;
  }
`

const GET_GOALS = gql`
  query getGoals{
    getGoals{
      generalGoals{
        name
        currentValue
        goalValue
        type
      }
      specificGoals{
        name
        currentValue
        goalValue
        type
      }
    }
  }
`

const NEW_GOAL = gql`
  mutation createGoal($goalInfo: NewGoalArgs!){
    createGoal(goalInfo: $goalInfo){
      generalGoals{
        name
        currentValue
        goalValue
        type
      }
      specificGoals{
        name
        currentValue
        goalValue
        type
      }
    }
  }
`

const DELETE_GOAL = gql`
  mutation deleteGoal($goalName: String!){
    deleteGoal(goalName: $goalName){
      generalGoals{
        name
        currentValue
        goalValue
        type
      }
      specificGoals{
        name
        currentValue
        goalValue
        type
      }
    }
  }
`

const ChoiceContainer = styled.div`
${tw`
flex
flex-col
items-center
fixed
`}
border: 1px solid #888888;
top: 40%;
border-radius: 15px;
width: 300px;

background-color: #242424;

.textDiv{
color: ${secondaryText};
text-align: center;
font-size: 16px;
padding: 15px 15px;
}

.buttonDiv{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.choiceButton{
width: 50%;
color: #00dada; 
font-size: 18px;
font-weight: 600;
padding: 5px 10px;
border: 1px solid #888888;
background-color: ${mainBackground};
}

.left{
border-bottom-left-radius: 15px;

}
.right{
border-bottom-right-radius: 15px;

}
`


export default function ObjectivesPage(){
  const {loading, error, data, refetch} = useQuery(GET_GOALS);
  const [newGoal, {loadingGoal, errorGoal, data: dataGoal}] = useMutation(NEW_GOAL, {
    onError: (e) => {
      setError(true);
      setTimeout( () => {
        setError(false);
      }, 1500);
    },
    onCompleted: () => {
      setCorrect(true);
      setTimeout( () => {
        setCorrect(false);
        handleSetAllDataUpdated();
      }, 1500);
    }
  });
  const [deleteObj, {loadingDelete, errorDelete, data: dataDelete}] = useMutation(DELETE_GOAL, {
    onError: (e) => {
      setDeleteUncorrect(true);
      setTimeout( () => {
        setDeleteUncorrect(false);
      }, 1500);
    },
    onCompleted: () => {
      setDeleteCorrect(true);
      setTimeout( () => {
        setDeleteCorrect(false);
        handleSetAllDataUpdated();
      }, 1500);
    }
  });


  const [goalInfo, setGoalInfo] = useState({
    name: null,
    goalValue: null
  });

  const [personalGoals, setPersonals] = useState([]);

  const [monthlyGain, setMonthlyGain] = useState(null);
  const [monthlySales, setMonthlySales] = useState(null);
  const [yearlyGain, setYearlyGain] = useState(null);
  const [yearlySales, setYearlySales] = useState(null);
  const [showForm, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [type, setType] = useState(goalTypes[0]);
  const [fixedName, setFixed] = useState(null);
  const [errorMessage, setError] = useState(false);
  const [correctMessage, setCorrect] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState("");
  const [deleteCorrect, setDeleteCorrect] = useState(false);
  const [deleteUncorrect, setDeleteUncorrect] = useState(false);

  const goalRef = useRef()

  useEffect( () => {
    if(data){
      const {generalGoals, specificGoals} = data.getGoals;
      
      const monGain = generalGoals.find(goal => goal.name === "monthlyGain");
      monGain ? setMonthlyGain({
        currentValue : monGain.currentValue,
        goalValue : monGain.goalValue,
        width: `${Math.min(Math.floor((monGain.currentValue / monGain.goalValue) * 100), 100)}%`
      }) : setMonthlyGain(null);

      const monSales = generalGoals.find(goal => goal.name === "monthlySales");
      monSales ? setMonthlySales({
        currentValue : monSales.currentValue,
        goalValue : monSales.goalValue,
        width: `${Math.min(Math.floor((monSales.currentValue / monSales.goalValue) * 100), 100)}%`
      }) : setMonthlySales(null);

      const yearGain = generalGoals.find(goal => goal.name === "yearlyGain");
      yearGain ? setYearlyGain({
        currentValue : yearGain.currentValue,
        goalValue : yearGain.goalValue,
        width: `${Math.min(Math.floor((yearGain.currentValue / yearGain.goalValue) * 100), 100)}%`
      }) : setYearlyGain(null);

      const yearSales = generalGoals.find(goal => goal.name === "yearlySales");
      yearSales ? setYearlySales({
        currentValue : yearSales.currentValue,
        goalValue : yearSales.goalValue,
        width: `${Math.min(Math.floor((yearSales.currentValue / yearSales.goalValue) * 100), 100)}%`
      }) : setYearlySales(null);

      setPersonals([...specificGoals]);
    }
  }, [data])

  useEffect( () => {
    if(dataGoal){
      const {generalGoals, specificGoals} = dataGoal.createGoal;
      
      const monGain = generalGoals.find(goal => goal.name === "monthlyGain");
      monGain ? setMonthlyGain({
        currentValue : monGain.currentValue,
        goalValue : monGain.goalValue,
        width: `${Math.min(Math.floor((monGain.currentValue / monGain.goalValue) * 100), 100)}%`
      }) : setMonthlyGain(null);

      const monSales = generalGoals.find(goal => goal.name === "monthlySales");
      monSales ? setMonthlySales({
        currentValue : monSales.currentValue,
        goalValue : monSales.goalValue,
        width: `${Math.min(Math.floor((monSales.currentValue / monSales.goalValue) * 100), 100)}%`
      }) : setMonthlySales(null);

      const yearGain = generalGoals.find(goal => goal.name === "yearlyGain");
      yearGain ? setYearlyGain({
        currentValue : yearGain.currentValue,
        goalValue : yearGain.goalValue,
        width: `${Math.min(Math.floor((yearGain.currentValue / yearGain.goalValue) * 100), 100)}%`
      }) : setYearlyGain(null);

      const yearSales = generalGoals.find(goal => goal.name === "yearlySales");
      yearSales ? setYearlySales({
        currentValue : yearSales.currentValue,
        goalValue : yearSales.goalValue,
        width: `${Math.min(Math.floor((yearSales.currentValue / yearSales.goalValue) * 100), 100)}%`
      }) : setYearlySales(null);

      setPersonals([...specificGoals]);
    }
  }, [dataGoal])

  useEffect( () => {
    if(dataDelete){
      const {generalGoals, specificGoals} = dataDelete.deleteGoal;
      
      const monGain = generalGoals.find(goal => goal.name === "monthlyGain");
      monGain ? setMonthlyGain({
        currentValue : monGain.currentValue,
        goalValue : monGain.goalValue,
        width: `${Math.min(Math.floor((monGain.currentValue / monGain.goalValue) * 100), 100)}%`
      }) : setMonthlyGain(null);

      const monSales = generalGoals.find(goal => goal.name === "monthlySales");
      monSales ? setMonthlySales({
        currentValue : monSales.currentValue,
        goalValue : monSales.goalValue,
        width: `${Math.min(Math.floor((monSales.currentValue / monSales.goalValue) * 100), 100)}%`
      }) : setMonthlySales(null);

      const yearGain = generalGoals.find(goal => goal.name === "yearlyGain");
      yearGain ? setYearlyGain({
        currentValue : yearGain.currentValue,
        goalValue : yearGain.goalValue,
        width: `${Math.min(Math.floor((yearGain.currentValue / yearGain.goalValue) * 100), 100)}%`
      }) : setYearlyGain(null);

      const yearSales = generalGoals.find(goal => goal.name === "yearlySales");
      yearSales ? setYearlySales({
        currentValue : yearSales.currentValue,
        goalValue : yearSales.goalValue,
        width: `${Math.min(Math.floor((yearSales.currentValue / yearSales.goalValue) * 100), 100)}%`
      }) : setYearlySales(null);

      setPersonals([...specificGoals]);
    }
  }, [dataDelete])

  

  function setUpForm(newName, newType){
    setGoalInfo(prevGoal => ({
      ...prevGoal,
      name: newName ? newName : prevGoal.name
    }));
    setType(newType);
    setFixed(newName);
    setShow(true);
  }

  function createGoal(event){
    event.preventDefault()
    try{
      newGoal({
        variables: {
          goalInfo: {
            name: fixedName ? getGoalName(fixedName) : goalInfo.name,
            goalValue: parseFloat(goalInfo.goalValue),
            type: getGoalType(type)
          }
        }
      })
    }
    catch(error){
      console.log("errore!!", error)
    }
    closeForm();
  }

  function closeForm(){
    setType(goalTypes[0]);
    setGoalInfo({
      goalValue: null,
      name: null
    });
    setFixed(null);
    setShow(false);
  }

  function handleInput(event){
    const {name, value} = event.target;
    setGoalInfo( prevGoal => {
      return(
        {
          ...prevGoal,
          [name]: value
        }
      )
    });
  }

  const showDeleteChoice = useCallback((goalToEliminate) => {

    setShowDelete(true);
    setGoalToDelete(goalToEliminate);
  }
  )

  const closeDeleteWindow = useCallback(() => {
    setGoalToDelete("");
    setShowDelete(false);
  })

  const deleteGoal = useCallback(() => {
    try{
      deleteObj({
        variables: {
          goalName: goalToDelete
        }
      })
      
    }
    catch(error){
      console.log(error);
    }
    closeDeleteWindow();
  });

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'goalData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  useEffect( ( ) => {
    if(state.dataUpdated.goalData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  return(
    <PageContainer>
      <HeaderFixed pageTitle="OBIETTIVI"/>
      {!loading ? <div><GoalContainer>
        <GoalTitle>
          Obiettivi predefiniti
        </GoalTitle>
        <Goals>
          <Goal width={monthlyGain && monthlyGain.width}>
            <BarText>
              <span className="goalName">Guadagno mensile</span>
              {monthlyGain && <img src={deleteIcon} onClick={() => showDeleteChoice("monthlyGain")}/>}
              {monthlyGain && <span className="completato">Completato</span>}
            </BarText>
            {monthlyGain ?
              <BarGroup>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{monthlyGain.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {monthlyGain.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {monthlyGain.goalValue}€</span>
                </NumericValues>
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={() => {setUpForm("Guadagno mensile", "Guadagno")}}/>
              }
          </Goal>
          <Goal width={monthlySales && monthlySales.width}>
            <BarText>
              <span className="goalName">Vendite mensili</span>
              {monthlySales && <img src={deleteIcon} onClick={() => showDeleteChoice("monthlySales")}/>}
              {monthlySales && <span className="completato">Completato</span>}
            </BarText>
            {monthlySales ?
              <BarGroup>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{monthlySales.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {monthlySales.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {monthlySales.goalValue}€</span>
                </NumericValues>              
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={() => {setUpForm("Vendite mensili", "Totale")}}/>
              }
          </Goal>
          <Goal width={yearlyGain && yearlyGain.width}>
            <BarText>
              <span className="goalName">Guadagno annuale</span>
              {yearlyGain && <img src={deleteIcon} onClick={() => showDeleteChoice("yearlyGain")}/>}
              {yearlyGain && <span className="completato">Completato</span>}
            </BarText>
            {yearlyGain ?
              <BarGroup>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{yearlyGain.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {yearlyGain.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {yearlyGain.goalValue}€</span>
                </NumericValues>              
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={ () => {setUpForm("Guadagno annuale", "Guadagno")}}/>
              }
          </Goal>
          <Goal width={yearlySales && yearlySales.width}>
            <BarText>
              <span className="goalName">Vendite annuali</span>
              {yearlySales && <img src={deleteIcon} onClick={() => showDeleteChoice("yearlySales")}/>}
              {yearlySales && <span className="completato">Completato</span>}
            </BarText>
            {yearlySales ?
              <BarGroup>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{yearlySales.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {yearlySales.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {yearlySales.goalValue}€</span>
                </NumericValues>              
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={() => {setUpForm("Vendite annuali", "Totale")}}/>
              }
          </Goal>
        </Goals>
      </GoalContainer>
      <GoalContainer>
        <GoalTitle>
          Obiettivi personali
        </GoalTitle>
      {personalGoals.length > 0 &&
          <Goals>
            {personalGoals.map( (goal, index) => (
              <Goal key={index} width={`${Math.min(Math.floor((goal.currentValue / goal.goalValue) * 100), 100)}%`}>
              <BarText>
                <span className="goalName">{goal.name}</span>
                <img src={deleteIcon} onClick={() => showDeleteChoice(goal.name)}/>
                <span className="completato">Completato</span>
              </BarText>
                <BarGroup>
                  <BarValue>
                    <ProgressBar
                      className="wrapper"
                      barContainerClassName="barContainer"
                      completedClassName="barCompleted"
                      isLabelVisible={false}
                    />
                    <span className="percent">{`${Math.min(Math.floor((goal.currentValue / goal.goalValue) * 100), 100)}%`}</span>
                  </BarValue>
                  <NumericValues>
                    <span className="currentValue">Raggiunto: {goal.currentValue}€</span>
                    <span className="goalValue">Obiettivo: {goal.goalValue}€</span>
                  </NumericValues>                
                </BarGroup>
            </Goal>
            ))}
          </Goals>
      }
      <MainButton text="Crea obiettivo" active={true} fontSize="14px" onClickFunction={() => {setShow(true)}}/>
      </GoalContainer>
      <Transition.Root show={showForm} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showForm}
          onClose={() => closeForm()}
          initialFocus={goalRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <FormContainer>
                <FormTitle ref={goalRef}>Definisci obiettivo</FormTitle>
                <FormField width={"260px"} name="Nome obiettivo" inputName="name" value={goalInfo.name} handler={handleInput} fontDim="14px" type="text" readonlyF={true} tabIndex="-1"/>
                <FormField width={"260px"} name="Valore obiettivo" inputName="goalValue" value={goalInfo.goalValue} handler={handleInput} fontDim="14px" type="number"/>
                <CSelector options={goalTypes} val={type} func={setType}/>
                <MainButton active={true} text="Crea obiettivo" fontSize="14px" onClickFunction={(e) => createGoal(e)}/>
              </FormContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> 
      <Transition.Root show={showDelete} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showDelete}
          onClose={() => closeDeleteWindow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ChoiceContainer>
                <div className="textDiv">
                  Sei sicuro di voler eliminare questo obiettivo?
                </div>
                <div className="buttonDiv">
                  <button className="choiceButton left" onClick={() => deleteGoal()}>Si</button>
                  <button className="choiceButton right" onClick={() => closeDeleteWindow()}>No</button>
                </div>
              </ChoiceContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root></div>
      :
      
      <LoadingAnimation />}
      {errorMessage && <ErrorPopUp text="Obiettivo non salvato" />}
      {correctMessage && <CorrectPopUp text="Obiettivo salvato" />}
      {deleteUncorrect && <ErrorPopUp text="Obiettivo non cancellato" />}
      {deleteCorrect && <CorrectPopUp text="Obiettivo cancellato" />}
      <Navfixed currentPage="goal" />
    </PageContainer>
  )
}