import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import watch from "../icons/watch_icon.png"
import shoe from "../icons/shoe_icon.png"
import generic from "../icons/generic.png"
import { formatDate, secondaryText } from "../const";
import nike from "../icons/img01.jpg"
import nike2 from "../icons/img02.jpg"

const TransactionBox = styled.div`
  ${tw`
      flex
      flex-col
  `}
  color: ${secondaryText};
`

const Transaction = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${tw`
      flex
  `}
  align-items: center;
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px outset ${secondaryText};
  height: 61px;
  width: 100%;

  img{
    width: 80px;
    height: 60px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  ${props => props.animate && 'animation: appear 0.2s ease-in-out;'}
`

const TransactionDesc = styled.div`
  ${tw`
      flex
      flex-col
      ml-2
  `}
  width: 50%;
  .item_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    width: 63%;
    margin-bottom: 5px;
  }

  .item_date{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 10px;
  }
`

const TransactionPrice = styled.div`
  ${tw`
      flex
      flex-col
  `}
  align-items: center;
  font-size: 15px;

  .price-text{
    font-size: 10px;
  }

  .sellPrice{
    color: #61DE70;
  }

  .buyPrice{
    color: #de6161;
  }
`

const ShoeIconContainer = styled.div`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 50px; 
    height: 50px;
  }
`

const WatchIconContainer = styled.div`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 50px; 
    height: 50px;
  }
`

const GenericIconContainer = styled.div`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 40px; 
    height: 40px;
  }
`

const DatabaseShoe = styled.img`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  ${props => !props.roundImg && 'border-bottom-left-radius: 15px;'}
`

export const mainPurple = "#9327F0";

export const mainFucsia = "#EC27F0";


export default function ItemField(props){
  const {item, sales, index, nItems, infoFunc} = props;

  function showInfoCard(){
    infoFunc(item);
  }

  return(
    <TransactionBox>
    {
      sales ? (
        <Transaction id={index} key={index} animate={index >= nItems}>
          {item.type == "shoe" && !item.sneaker && <ShoeIconContainer>
              <img src={shoe} />
            </ShoeIconContainer>}
            {item.sneaker && 
            <DatabaseShoe src={item.sneaker.ShoeImage} />
            }
            {item.type == "watch" && <WatchIconContainer>
              <img src={watch} />
            </WatchIconContainer>}
            {item.type == "other" && <GenericIconContainer>
              <img src={generic} />
            </GenericIconContainer>}
          <TransactionDesc>
            <span className="item_name">{item.name}</span>
            <span className="item_date">Venduto: {formatDate(item.sellDate)}</span>
          </TransactionDesc>
          <TransactionPrice>
            <span className="price-text">Prezzo vendita</span>
            <span className="sellPrice">+${item.sellPrice}</span>
          </TransactionPrice>
        </Transaction>
      ) : (
        <Transaction>
          {item.type == "shoe" && !item.sneaker && <ShoeIconContainer>
              <img src={shoe} />
            </ShoeIconContainer>}
            {item.sneaker && 
            <DatabaseShoe src={item.sneaker.ShoeImage} />
            }
            {item.type == "watch" && <WatchIconContainer>
              <img src={watch} />
            </WatchIconContainer>}
            {item.type == "other" && <GenericIconContainer>
              <img src={generic} />
            </GenericIconContainer>}
          <TransactionDesc>
            <span className="item_name">{item.name}</span>
            <span className="item_date">Acquistato: {formatDate(item.buyDate)}</span>
          </TransactionDesc>
          <TransactionPrice>
            <span className="price-text">Prezzo acquisto</span>
            <span className="buyPrice">-${item.buyPrice}</span>
          </TransactionPrice>
        </Transaction>)
    }
    </TransactionBox>
  )
}


