import shoe from "./icons/shoe_icon.png"
import watch from "./icons/watch_icon.png"
import generic from "./icons/generic.png"

import moment from 'moment';
import 'moment/locale/it'; // Import Italian locale

function italianDate(input) {
    moment.locale('it'); // Set locale to Italian
    const today = moment();
    switch (input) {
        case 'week':
            const startOfWeek = today.clone().startOf('isoWeek');
            const endOfWeek = today.clone().endOf('isoWeek');
            return `${startOfWeek.format('D MMMM')} - ${endOfWeek.format('D MMMM')}`;
        case 'month':
            const capitalizedMonth = today.format('MMMM').charAt(0).toUpperCase() + today.format('MMMM').slice(1);
            return `${capitalizedMonth} ${today.format('YYYY')}`;
        case 'year':
            return today.format('YYYY');
        default:
            return 'Invalid input';
    }
}

export function allDate(totalExpenses) {
  if(totalExpenses.length > 0){
    var dateString1 = totalExpenses[0].date
    var dateString2 = totalExpenses[totalExpenses.length - 1].date
    const date1 = moment(dateString1);
    const date2 = moment(dateString2);
  
    const monthYear1 = date1.format('MMMM YYYY');
    const monthYear2 = date2.format('MMMM YYYY');
  
    return `${monthYear1} - ${monthYear2}`;
  } else{
      const date1 = moment(new Date());
    
      const monthYear1 = date1.format('MMMM YYYY');
    
      return `${monthYear1} - ${monthYear1}`;
  }
}

export default italianDate;


export const mainPurple = "#006d6d";

export const iconColor = "#00B6B6";
export const mainFucsia = "#00dada";

export const secondaryBackground = "#242424"
export const mainBackground= "#181818"

export const secondaryText = "#EFEFEF"

export const mediumInput = "140px"

export const longInput = "300px"

export const loginInput = "260px"

export const typeOptions = ["Generico", "Scarpa", "Orologio"]

export const shoeSize = ["Taglia", 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5,44, 44.5, 45, 
                        45.5,46, 46.5,47, 47.5];

                        
export const goalTypes = ["Guadagno", "Totale"];

export const getGoalType = (type) => {
  if (type === "Guadagno"){
    return("gain");
  }
  else{
    return("sales")
  }
}

export const getGoalName = (name) => {
  if (name === "Vendite mensili") return "monthlySales"
  else if (name === "Guadagno mensile") return "monthlyGain"
  else if (name === "Guadagno annuale") return "yearlyGain"
  else if (name === "Vendite annuali") return "yearlySales"
}

export const fakeData = [ 
  {
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
  {
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
  {
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
  {
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
]

export const fakeSold = [
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
{
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
{
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
{
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
]

export const fakeUnsold = [
  {
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
{
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
{
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
{
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
]

/**
 * funzioni comuni
 */

export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('it-IT', options).format(date);
  return formattedDate;
}

export const getIcon = (type) => {
  return(type === "shoe" ? shoe : 
    type === "watch" ? watch :
      generic
  );
}

export const getForfettaryTaxes = (total, years) => {
  var percent = 0;
  years > 5 ? percent = 0.15 : percent = 0.05;

  var utile = total * 0.4;

  return({
    taxesToPay: Math.round(utile * 0.05),
    contributi: Math.round(utile * 0.24)
  })
}

export const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre"
]

export const years = () => {
  const today = new Date();
  const year = today.getFullYear();

  return( [year, year - 1]);
};

export const mapMonth = (month) => {
  var index = months.indexOf(month);
  return (index + 1);
}

export const monthStringFromLabel = (monthLabel) => {
  var monthString = "";
  switch(monthLabel){
    case "01": 
      monthString = "Gennaio";
      break;
      case "02": 
      monthString = "Febbraio";
      break;
      case "03": 
      monthString = "Marzo";
      break;
      case "04": 
      monthString = "Aprile";
      break;
      case "05": 
      monthString = "Maggio";
      break;
      case "06": 
      monthString = "Giugno";
      break;
      case "07": 
      monthString = "Luglio";
      break;
      case "08": 
      monthString = "Agosto";
      break; 
      case "09": 
      monthString = "Settembre";
      break;
      case "10": 
      monthString = "Ottobre";
      break;
      case "11": 
      monthString = "Novembre";
      break;
      case "12": 
      monthString = "Dicembre";
      break;
    default: break;
  }
  return monthString
};

export const monthFromLabel = (labels) => {
  return labels.map( (obj) => {
    return monthStringFromLabel(obj.slice(-2));
  });
}

export const limiteForfettario = 85000;

export const shoeSizes = {
  USMen: [
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    12.5,
    13,
    13.5,
    14,
    14.5,
    15
  ],
  USWomen: [
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12
  ],
  GS: [
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7
  ],
  EU: [30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50, 50.5, 51]
}

export const timeExpensePeriods = ["Mensile", "Trimestrale", "Semestrale", "Annuale"]

export function stringToArray(inputString) {
  // Use the split method to split the string into an array
  // based on the comma and space (", ") delimiter
  var resultArray = inputString.split(", ");

  // Trim whitespace from each element in the array
  resultArray = resultArray.map(function (element) {
      return element.trim();
  });

  return resultArray;
}

export function calculateExpiryDate(period, dateString) {
  const currentDate = new Date(dateString);
  let expiryDate = new Date(dateString);

  switch (period) {
    case "mensile":
      expiryDate.setMonth(currentDate.getMonth() + 1)
      break;
    case "trimestrale":
      expiryDate.setMonth(currentDate.getMonth() + 3)
      break;
    case "semestrale":
      expiryDate.setMonth(currentDate.getMonth() + 6)
      break;
    case "annuale":
      expiryDate.setMonth(currentDate.getMonth() + 12)
      break;
    default:
      break;
  }
  return expiryDate.toISOString().split('T')[0]; // Return only the date part
}