import LoginForm from "../components/LoginForm";
import styled from "styled-components"
import tw from "twin.macro";
import { loginInput } from "../const";
import { BlueCircle, GreenCircle, BlackCircle} from "../styledElements";
import banner_logo from "../logos/finix_full_dark_bg.png"

const PageDiv = styled.div`
  position: relative;
  overflow-x: hidden;
`

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;

  img{
    width: 60%;
    height: auto;
  }
`

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LoginText = styled.span`
  ${tw`
      text-white  
      px-4
  `}
  width: ${loginInput};
  font-size: 35px;
  font-weight: 600;

  .colored{
    color: #00dada;
  }
`

export default function LoginPage(){
  
  return(
    <PageDiv>
      <BannerContainer>
        <img src={banner_logo} />
      </BannerContainer>
      <LoginContainer>
        <LoginText>
          Log <span className="colored">in</span>
        </LoginText>
        <LoginForm>

        </LoginForm>
      
      </LoginContainer>
    </PageDiv>
  )
}