import RegistrationForm from "../components/RegistrationForm";
import { useNavigate } from "react-router-dom";
import styled from "styled-components"
import tw from "twin.macro";
import { longInput } from "../const";
import back_arrow from "../icons/back_arrow.png"
import { BlueCircle, GreenCircle, BlackCircle} from "../styledElements";

const PageDiv = styled.div`
  position: relative;
  overflow-x: hidden;
`

const Arrow = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 40px;
  margin-left: 40px;
  z-index: 2;
`

const RegistrationContainer = styled.div`
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default function RegistrationPage(){
  const navigate = useNavigate();
  return(
    <PageDiv>
      <Arrow onClick={ () => {
        navigate('/Login');
      }}>
        <img src={back_arrow} />
      </Arrow>
      <GreenCircle />
      <BlueCircle />
      <BlackCircle />
      <RegistrationContainer>
        <RegistrationForm />
      </RegistrationContainer>
    </PageDiv>
  )
}