import React from "react";
import styled from "styled-components"
import tw from "twin.macro";

const ButtonContainer = styled.div`
  ${tw`
      flex
      justify-center
  `}
  padding-top: ${props => props.paddingTop}
`

const PrimaryButton = styled.button`
  ${tw`
      px-10
      py-2
      rounded-xl 
      text-center
      font-semibold
      mt-4
    `}
    ${props => props.width && 'width: 150px;'}
    position: relative;
    overflow: hidden;
    font-size: ${props => props.fontSize || '18px'};
    background: transparent;
    ${props => props.color ? `color: ${props.color};` : 'color: #00dada;'}
    ${props => props.color ? `border: 1px solid ${props.color};` : 'border: 1px solid #00dada;'}
`;

const Ripple = styled.span`
  @keyframes rip{
    0%{
      transform: scale(0);
      opacity: 1;
    }
    100%{
      transform: scale(4);
      opacity: 0;
    }
  }

  position: absolute;
  border-radius: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  transform: scale(0);
  animation: rip 500ms linear;
  ${props => props.color ? `background: ${props.color};` : 'background: #00dada;'}
  `

const BlockedButton = styled.button`
  ${tw`
      text-opacity-80
      px-10
      py-2
      rounded-xl 
      text-white 
      text-center
      font-semibold
      mt-4
    `}
    ${props => props.width && 'width: 150px;'}
  font-size: ${props => props.fontSize || '18px'};
  background: transparent;
  border: 1px solid #EFEFEF;
`;

export default function MainButton(props){
  const {onClickFunction, color} = props;
  const [ripple, setRipple] = React.useState(false);

  function handleClick(event){
    setRipple(true);
    setTimeout( () => {
      setRipple(false);
      onClickFunction && onClickFunction(event);
    }, 300);
  }

  return(
    <ButtonContainer paddingTop = {props.paddingTop} onClick={handleClick}>
      {props.active && <PrimaryButton width = {props.width} type="submit" fontSize={props.fontSize} color={color || null}>
        {props.text}
        {ripple && <Ripple color={color || null}/>}
      </PrimaryButton>}

      {!props.active && <BlockedButton width = {props.width} type="submit" disabled  fontSize={props.fontSize}>
        {props.text}
      </BlockedButton>}
    </ButtonContainer>
  )
}