import React, { Fragment } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { fakeData, formatDate, getIcon, limiteForfettario, mainBackground, secondaryText, years } from "../const"
import Navfixed from "../components/Navfixed"
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import nike from "../icons/img01.jpg"
import nike2 from "../icons/img02.jpg"
import { useNavigate } from "react-router-dom"
import ItemField from "../components/itemField"
import HeaderFixed from "../components/HeaderFixed"
import MainButton from "../components/MainButton"
import roundAlert from "../icons/roundAlert.png";
import LoadingAnimation from "../components/LoadingAnimation"
import { Dialog, Transition } from "@headlessui/react"
import HomePageChart from "../components/HomePageChart"
import { actionTypes, sellActionTypes, useGlobalState } from "../GlobalStateContext"
import ItemWarehouse from "../components/ItemWarehouse"


const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const TransactionsContainer = styled.div`
  ${tw`
      flex
      flex-col
      mt-8
  `}
  margin-left: 15px;
  margin-right: 15px;
  color: rgba(255,255,255,0.8);
  width: 100%;
`

const TransactionTitle = styled.div`
  color: ${secondaryText};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
  margin-left: 10px;
`

const TransactionBox = styled.div`
  width: 90%;
`

const Transaction = styled.div`
  ${tw`
      flex
  `}
  align-items: center;
  margin-bottom: 15px;
  border-radius: 15px;
  border: 1px outset ${secondaryText};
  height: 61px;
  width: 95%;
  position: relative;

  img{
    width: 80px;
    height: 60px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
`

const TransactionDesc = styled.div`
  ${tw`
      flex
      flex-col
      ml-2
  `}

  .item_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    width: 63%;
    margin-bottom: 5px;
  }

  .item_date{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 10px;
  }
`

const TransactionPrice = styled.div`
  ${tw`
      flex
      flex-col
  `}
  align-items: center;
  position: absolute;
  right: 15px;
  font-size: 15px;

  .price-text{
    font-size: 10px;
  }

  .sellPrice{
    color: #61DE70;
  }

  .buyPrice{
    color: #de6161;
  }
`


const PageContainer= styled.div`
  ${tw`
      flex
      flex-col
      items-center
      justify-center
  `}
  margin-top: 20px;
`

const BoxContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BoxRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`

const Box = styled.div`
  width: 45%;
  padding: 18px 13px;
  height: 90px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  color: white;
  border: 2px solid black;
  box-shadow: 0px 1px 1px #00b6b6;

  .title{
    font-weight: 600;
    font-size: 0.9em;
    color: #00dada;
  }

  .value{
    color:white;
    font-size: 1.5em;
    font-weight: 600;
    margin-left: 3px;
  }

  .subValue{

  }

  .euro{
    font-size: 0.8em;
    opacity: 0.9;
    margin-left: 3px;
  }

  .better{
    margin-left: 2px;
    color: #61DE70;
    font-size: 0.7rem;
  }

  .worse{
    margin-left: 2px;
    color: #de6161;
    font-size: 0.7rem;
  }
`

const GET_DATA = gql`
  query homePageData{
    homePageData{
      totalGain,
      warehouse,
      currentSales,
      previousSales,
      liquidity,
      yearSales,
      name,
      lastTransactions{
        id
        name
        buyPrice
        expectedSellPrice
        sellPrice
        sellDate
        type
        buyDate
        tags{
          text
        }
        shoe{
          sizeEU
          gender
        }
        watch{
          modelNumber
          serialNumber
        }
        sneaker{
          SKU
          ShoeImage
          ShoeName
        }
      }
    }
  }
`

const WelcomeText = styled.div`
  ${tw`
      text-white
      font-semibold
      mb-6
  `}
  margin-top: 10px;
  margin-left: 8%;
  font-size: 30px;
  margin-bottom: 15px;

  .welcomeText {
    padding-bottom: 1px;
  }
`;

const StyledLink = styled.div`
  ${tw`
      flex
      w-full
      text-center
  `}
  margin-top: -5px;
  font-size: 18px;
  font-weight: 500;

  .linkText{
    width: 100%;
    color: white;
  }
`

const CenterFlex = styled.div`
  ${tw`
      flex
      w-full
      justify-center
  `}
`

const InfoContainer = styled.div`
  ${tw`
      text-white
      py-4
      flex
      flex-col
      items-center
  `}
  width: 80%;
  position: fixed;
  top: 15%;
  border-radius: 15px;
  background: ${mainBackground};

  img{
    margin-top: 10px;
    width: 50%;
  }

  .iconImage{
    width: 50px;
    height: 50px;
  }

  .itemName{
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 80%;
    word-wrap: break-word;
  }
`

const InfoRow = styled.div`
  ${tw`
      flex
      justify-between
  `}
  font-size: 14px;
  width: 70%;
  margin-bottom: 8px;
`

const InfoGroupTitle = styled.div`
  font-size: 16px;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
`
const HomeChartContainer = styled.div`
  ${tw`
      mb-5
  `}
`

const SectionTitle = styled.p`
  ${tw`
      text-white
  `}
  font-size: 18px;
  margin-left: 25px;
  font-weight: 600;
`

const SectionParagraph = styled.p`
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
`

export default function HomePage(){
  const navigate = useNavigate();
  const {loading, error, data, refetch} = useQuery(GET_DATA);


  const [top5, setTop5] = React.useState([]);
  const [worst5, setWorst5] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [gain, setGain] = React.useState(0);
  const [sales, setSales] = React.useState(0);
  const [currentBetter, setCurrentBetter] = React.useState(true);
  const [percent, setPercent] = React.useState("");
  const [warehouse, setWarehouse] = React.useState(0);
  const [liquidity, setLiquidity] = React.useState(0);
  const [name, setName] = React.useState("");
  const [nItems, setNItems] = React.useState(5);
  const [prevN, setPrev] = React.useState(0);
  const [itemForInfo, setItem] = React.useState(null);
  const [showInfo, setShow] = React.useState(false);
  const [homeDataSales, setHomeSales] = React.useState({
    labels: ['Vendite attuali', 'Rimanente per il limite'],
    data: [0, 80000]
  });

  React.useEffect( () => {
    if (data){
      const {top5, worst5, totalGain, previousSales, currentSales, warehouse, liquidity, lastTransactions, yearSales} = data.homePageData;
      setTop5(top5? top5: null);
      setWorst5(worst5? worst5 : null);
      setTransactions(lastTransactions ? lastTransactions : null);
      setGain(totalGain? totalGain : 0);
      setSales(currentSales? currentSales : 0);
      if (currentSales && previousSales){
        previousSales > currentSales ?
          setPercent(Math.floor(100 - (currentSales / previousSales) * 100)) :
          setPercent(Math.floor((currentSales / previousSales) * 100 - 100))
        setCurrentBetter(currentSales > previousSales);
      }
      else{
        setPercent(0);
        setCurrentBetter(true);
      }
      setWarehouse(warehouse ? warehouse : 0);
      setLiquidity(liquidity? liquidity : 0);
      setName(data.homePageData.name);
      setHomeSales(prevHome => {
        return{
          ...prevHome,
          data: [(yearSales || 0), limiteForfettario - (yearSales || 0)]
        }
      });
    }
  }, [data, loading]);


  function changeNItems(){
    setPrev(nItems);
    setNItems(10);
  }

  function goToWarehouse(){
    navigate('/magazzino');
  }

  function closeForm(){
    setShow(false);
  }

  function showInfoCard(item){
    setItem(item);
    setShow(true);
  }

  function goToSale(){
    setTimeout( ( ) => {
      handleSetItemToSell()
      navigate('/nuovoOggetto')
    }, 200)
  }

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'homeData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  React.useEffect( ( ) => {
    if(state.dataUpdated.homeData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  const handleSetItemToSell = () => {
    dispatch({
      type: sellActionTypes.SET_ITEM_TO_SELL,
      payload: {
        itemInfo: {...itemForInfo},
      }
    })
  }

  return(
    <div>
      <HeaderFixed pageTitle="HOME"/>
      {!loading ? 
      <PageContainer>
        <BoxContainer>
          <BoxRow>
            <Box>
              <p className="title">Guadagno totale</p>
              <p className="value guadagno">{gain}<span className="euro">€</span></p>
            </Box>
            <Box>
              <p className="title">Valore Magazzino</p>
              <p className="value magazzino">{warehouse}<span className="euro">€</span></p>
            </Box>
          </BoxRow>
          <BoxRow>
            <Box>
              <p className="title">Liquidità mensile</p>
              <p className="value liquidity">{liquidity}<span className="euro">€</span></p>
            </Box>
            <Box>
              <p className="title">Vendite mensili</p>
              <p className="value liquidity">{sales}<span className="euro">€</span> {currentBetter ? 
                  <span className="better">+{percent}%</span> :
                  <span className="worse">-{percent}%</span>
                }</p>
            </Box>
          </BoxRow>
        </BoxContainer>
        {homeDataSales && 
        <HomeChartContainer>
          <SectionTitle>
            Grafico vendite
          </SectionTitle>
          <SectionParagraph>
            Qui puoi vedere quanto ti stai avvicinando al limite previsto dal regime forfettario italiano.
          </SectionParagraph>
          <HomePageChart title={"Vendite"} data={homeDataSales}/>
        </HomeChartContainer>
        }
        {transactions.length > 0 && <TransactionsContainer>
          <TransactionTitle>
            <span>Ultime transazioni</span>
          </TransactionTitle>
          <CenterFlex>
            <TransactionBox>
              {transactions.slice(0, nItems).map((item, index) => (
                <ItemWarehouse 
                  item={item}
                  sales = {item.sellPrice}
                  index={index}
                  nItems={prevN}
                  infoFunc={showInfoCard}
                />
              ))}
              <StyledLink onClick={nItems <= 5 ? changeNItems : goToWarehouse}>
                <p className="linkText">
                {nItems <= 5 ? 
                  'Mostra altri' :
                  'Vai a magazzino'
                }
                </p>
              </StyledLink>
            </TransactionBox>
          </CenterFlex>
        </TransactionsContainer>}
        {transactions.length === 0 && 
          <NoItems>
            <img src={roundAlert} />
            <MessageContainer>
              <span>Sembra che tu non abbia registrato acquisti o vendite! Hai comprato o venduto qualcosa?</span>
            </MessageContainer>
            <MainButton active={true} text="Aggiungi" onClickFunction={() => {navigate('/nuovoOggetto');}}/>
          </NoItems>}
          <Transition.Root show={showInfo} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showInfo}
          onClose={() => closeForm()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
               {itemForInfo ? <InfoContainer>
                <img className={!itemForInfo.sneaker && 'iconImage'} src={itemForInfo.sneaker && itemForInfo.sneaker.ShoeImage || getIcon(itemForInfo.type)} />
                <span className="itemName">{itemForInfo.name}</span>
                <InfoGroupTitle>
                  Informazioni generali
                </InfoGroupTitle>
                <InfoRow>
                  <span className="itemBuyPrice">Prezzo di acquisto:</span>
                  <span>{itemForInfo.buyPrice}€</span>
                </InfoRow>
                <InfoRow>
                  <span className="itemExpectedPrice">Aspettativa di vendita:</span>
                  <span>{itemForInfo.expectedSellPrice}€</span>
                </InfoRow>
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Prezzo di vendita:</span>
                  <span>{itemForInfo.sellPrice}€</span>
                </InfoRow>}
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Guadagno:</span>
                  <span>{itemForInfo.sellPrice - itemForInfo.buyPrice}€</span>
                </InfoRow>}
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Guadagno %:</span>
                  <span>{Math.round((itemForInfo.sellPrice - itemForInfo.buyPrice) / itemForInfo.buyPrice * 100)}%</span>
                </InfoRow>}
                <InfoRow>
                  <span className="itemBuyDate">Data acquisto:</span>
                  <span>{formatDate(itemForInfo.buyDate)}</span>
                </InfoRow>
                {itemForInfo.sellDate && 
                <InfoRow> 
                  <span className="itemSellDate">Data vendita:</span>
                  <span>{formatDate(itemForInfo.sellDate)}</span>
                </InfoRow>}
                {itemForInfo.type == "shoe" && <InfoGroupTitle>
                  Informazioni scarpa
                </InfoGroupTitle>}
                {itemForInfo.type == "shoe" && 
                <InfoRow>
                  <span className="itemBuyDate">Taglia:</span>
                  <span>{itemForInfo.shoe.sizeEU} {itemForInfo.shoe.gender}</span>
                </InfoRow>}
                {itemForInfo.type == "watch" && <InfoGroupTitle>
                  Informazioni orologio
                </InfoGroupTitle>}
                {itemForInfo.type == "watch" && 
                <InfoRow>
                  <span className="itemBuyDate">Numero modello:</span>
                  <span>{itemForInfo.watch.modelNumber}</span>
                </InfoRow>}
                {!itemForInfo.sellPrice &&
                <MainButton active={true} text="Vendi" onClickFunction={goToSale}/>
                }
              </InfoContainer> : <div></div>}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      </PageContainer> :
      <LoadingAnimation />}
        <Navfixed currentPage={"home"}/>
    </div>
  )
}
