import Home from "../icons/homeIcon.png"
import homeColored from "../icons/homeColored.png"
import Warehouse from "../icons/Warehouse.png"
import warehouseColored from "../icons/warehouse_colored.png"
import Wallet from "../icons/Wallet.png"
import walletColored from "../icons/wallet_colored.png"
import addColored from "../icons/add_colored.png"
import addIcon from "../icons/addIcon.png"
import goalIcon from "../icons/goalIcon.png"
import goalColored from "../icons/goal_colored.png"
import styled from "styled-components"
import tw from "twin.macro";
import { iconColor } from "../const.js";
import { useNavigate } from "react-router-dom";
import React from "react"

const NavContainer = styled.nav`
  ${tw`
      fixed
      bottom-0
      w-full
  `}
  padding-top: 2px;
  padding-bottom: 1px;
  background: #181818;
  border-top: 1px solid #00b6b633;
`;

const IconContainer = styled.ul`
  ${tw`
      flex
      flex-row
      justify-around
      px-1
      items-center
  `}
  list-style: none;
`

const IconLink = styled.li`
  ${tw`
      flex
      flex-col
      justify-center
      items-center
      font-semibold
      text-center
  `}
  position relative;
  overflow: hidden;
  .colored {
    color: ${iconColor};
  }
  color: white;
  font-size: 10px;
  margin-bottom: ${props => props.padding || '0px'};

  img{
    width: ${props => props.size || '25px'};
    height: ${props => props.size || '25px'};
    margin-bottom: -2px;
  }
  padding: 0px 10px;
  border-radius: 10px;
`
const Ripple = styled.span`
  @keyframes rip{
    0%{
      transform: scale(0);
      opacity: 1;
    }
    100%{
      transform: scale(4);
      opacity: 0;
    }
  }

  position: absolute;
  border-radius: 50%;
  top: 50%;
  width: 400px;
  height: 40px;
  transform: scale(0);
  animation: rip 300ms linear;
  background-color: #00dada;
`

export default function Navfixed(props){
  const {currentPage} = props;
  const navigate = useNavigate();

  const [rippleString, setRipple] = React.useState(null);

  function navigateToPage(page){
    setRipple(page);
    navigate(`/${page}`);
  }


  return(
    <NavContainer>
      <IconContainer>
        <IconLink onClick={ () => {
          navigateToPage("home");
        }}>
          {currentPage === "home" ?
            <img src={homeColored}/> :
            <img src={Home} />
          }
          {currentPage === "home" && <Ripple />}
          <p className={currentPage === "home" ? "colored" : ""}>Home</p>
        </IconLink>
        <IconLink onClick={ () => {
          navigateToPage("magazzino");
        }}>
        {currentPage === "warehouse" ?
            <img src={warehouseColored}/> :
            <img src={Warehouse} />
          }
          {currentPage === "warehouse" && <Ripple />}
          <p className={currentPage === "warehouse" ? "colored" : ""}>Magazzino</p>
        </IconLink>
        <IconLink size="40px" padding="8px" onClick={ () => {
          navigateToPage("nuovoOggetto");
        }}>
          {currentPage === "addItem" ?
            <img src={addColored}/> :
            <img src={addIcon} />
          }
          {currentPage === "addItem" && <Ripple />}  
        </IconLink>
        <IconLink onClick={ () => {
          navigateToPage("contabilità");
        }}>
          {currentPage === "wallet" ?
            <img src={walletColored}/> :
            <img src={Wallet} />
          }
          {currentPage === "wallet" && <Ripple />}
          <p className={currentPage === "wallet" ? "colored" : ""}>Contabilità</p>
        </IconLink>
        <IconLink onClick={ () => {
          navigateToPage("goals");
        }}>
          {currentPage === "goal" ?
            <img src={goalColored}/> :
            <img src={goalIcon} />
          }
          {currentPage === "goal" && <Ripple />}
          <p className={currentPage === "goal" ? "colored" : ""}>Obiettivi</p>
        </IconLink>
      </IconContainer>
    </NavContainer>
  )
}