import React from "react";
import HeaderFixed from "../components/HeaderFixed";
import Navfixed from "../components/Navfixed";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import './css_files/GuidePage.css'
import styled from "styled-components"
import tw from "twin.macro";
import { secondaryText } from "../const";

const SectionParagraph = styled.p`
  margin-top: 20px;
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
`


export default function GuidePage(){
  return(
    <div>
      <HeaderFixed pageTitle="GUIDA" menuPage="GUIDE"/>
      <SectionParagraph>
        Questa app è stata creata per reseller e venditori indipendenti. Qui sotto una guida per utilizzare le varie funzionalità e rispondere a domande comuni.
      </SectionParagraph>
      <div className="w-full px-4 pt-8">
        <div className="mx-auto w-full max-w-md rounded-2xl bg-transparent p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>Magazzino</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6 customChevron`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  Nel magazzino potrai trovare l'inventario di tutti gli oggetti che devi ancora vendere e che hai venduto. Cliccando su un oggetto, potrai vederne le relative informazioni e ordinarli per parametri generali.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>Obiettivi</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  Nella sezione obiettivi potrai darti dei traguardi da raggiungere. Alcuni sono predefiniti, come guadagni e vendite periodici e vengono aggiornati automaticamente con ogni vendita/acquisto. Potrai inoltre proporti degli obiettivi personali, per cui deciderai te che vendite considerare (es: vendere 2000$ di Nike).
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>Dati</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  Nella sezione dati potrai vedere le tue vendite e guadagni totali, suddivisi tra i vari tipi di prodotti (scarpe, orologi o generici). Potrai inoltre vedere gli stessi valori annuali.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>Documenti</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  Nella sezione documenti potrai scaricare dei resoconti settimanali, mensili o annuali riguardo alla tua attività, con presenti liste di acquisti e vendite, dati sia generali sia specifici, e qualche indicazione statistica sull'andamento dell'attività.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>Contabilità</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                    Nella sezione contabilità potrai estrapolare dati interessanti riguardo ai tuoi acquisti e vendite, potendo filtrare la tua attività secondo molti parametri. Sono presenti anche grafici di andamento e a torta per visualizzare meglio la tua attività.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>Tasse</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                    Nella sezione tasse potrai vedere delle proiezioni dei tuoi guadagni annuali, e una stima delle tasse che dovrai pagare. (Per ora, limitatamente al regime forfettario)
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <Navfixed />
    </div>
  )
}