import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "../components/FormField";
import { loginInput, longInput } from "../const";
import MainButton from "../components/MainButton";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import OTPInput from "../components/OTPInput";
import ErrorMessage from "../components/ErrorMessage";
import lock from "../icons/sm_lock.png"
import email_icon from "../icons/sm_email.png"
import { useNavigate } from "react-router-dom";
import back_arrow from "../icons/back_arrow.png"
import { BlueCircle, GreenCircle, BlackCircle} from "../styledElements";
import CorrectPopUp from "../components/CorrectPopUp";
import ErrorPopUp from "../components/ErrorPopUp";

const PageDiv = styled.div`
  position: relative;
  overflow-x: hidden;
`

const Arrow = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 40px;
  margin-left: 40px;
  z-index: 2;
`

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.mt ? `${props.mt}px` : '150px')};
`

const TextContainer = styled.div`
  ${tw`
    mt-3
    mb-8
    font-medium
    pl-10
    pr-6
  `}
  color: rgba(255,255,255,0.8);
  font-size: 14px;
`

const SENDMAIL = gql`
    query($email: String!) {
      sendRecoveryEmail(email: $email)
    }
`;

const VERIFY_OTP = gql`
  query verifyOtp($email: String!, $otp: String!){
    verifyOtp(email: $email, otp: $otp)
  }
`

const RECOVER_PASSWORD = gql`
    mutation($email: String!, $password: String!) {
      recoveredPassword(email: $email, password: $password)
    }
`;

const RecoveryText = styled.span`
  ${tw`
      text-white  
      px-4
  `}
  width: 100%;
  font-size: 35px;
  font-weight: 600;
  padding-left: 8%;
  .colored{
    color: #00dada;
  }
`

const SendAgain = styled.button`
  ${tw`
      px-8
      py-2
      rounded-xl
      text-white
      mt-4
  `}
  font-size: 15px;
  background: ${props => props.active ? '#00dada' : 'transparent'};
  ${props => !props.active && 'border: 1px solid #00dada;'}
`

const SendText = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      mt-8
      mb-4
      font-medium
  `}
  color: rgba(255,255,255,0.8);
  font-size: 14px;
`
const MarginDiv = styled.div`
  margin-top: 10px;
`

export default function RecoveryPage(){
  const navigate = useNavigate();

  const [sendEmail, {loading: loadingEmail, data: dataEmail}] = useLazyQuery(SENDMAIL, {
      onError: () => {
        setEmail('');
        setEmailError(true);
        setTimeout( () => {
          setEmailError(false);
        }, 1500)
      },
      onCompleted: () => {
        setEmailCorrect(true);
        setTimeout( () => {
          setEmailCorrect(false);
          setPhase("otp")
        }, 1500)
      }
  });
  const [verifyOtp, {loading: loadingOTP, data: dataOTP}] = useLazyQuery(VERIFY_OTP, {
    onError: () => {
      setOtpError(true);
      setTimeout( () => {
        setOtpError(false);
      }, 1500)
    },
    onCompleted: () => {
      setOtpCorrect(true);
      setTimeout( () => {
        setOtpCorrect(false);
        setPhase("newPassword")
      }, 1500)
    }
  })
  const [recoverPassword, {loading: loadingPass, data: dataPass}] = useMutation(RECOVER_PASSWORD, {
    onError: () => {
      setPassError(true);
      setTimeout( () => {
        setPassError(false);
      }, 1500)
    },
    onCompleted: () => {
      setPassCorrect(true);
      setTimeout( () => {
        setPassCorrect(false);
        navigate('/login')
      }, 1500)
    }
  })
  const [email, setEmail] = React.useState("");
  const [valid, setValid] = React.useState(false);
  const [phase, setPhase] = React.useState("email");
  const [otpComplete, setOtpComplete] = React.useState(false);
  const [timeout, setTimer] = React.useState(60);
  const [otp, setOtp] = React.useState('');
  const [passInfo, setPassInfo] = React.useState({
    password: "",
    confirmPassword: ""
  })
  const [validation, setValidity] = React.useState({
    validPassword: true,
    samePassword: true
  });

  const [emailError, setEmailError] = React.useState(false);
  const [otpError, setOtpError] = React.useState(false);
  const [passError, setPassError] = React.useState(false);
  const [emailCorrect, setEmailCorrect] = React.useState(false);
  const [otpCorrect, setOtpCorrect] = React.useState(false);
  const [passCorrect, setPassCorrect] = React.useState(false);

  React.useEffect( () => {
    setOtpComplete(otp.length === 6);
  }, [otp])


  function handleEmail(event){
    const {value} = event.target;
    setEmail(value);
  }

  React.useEffect( () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValid(emailRegex.test(email));
  }, [email]);

  React.useEffect( () => {
    if (timeout > 0 && phase === "otp"){
      const countdown = setInterval( () => {
        setTimer((prevSeconds) => prevSeconds - 1);
      }, 1000)
      return () => clearInterval(countdown);
    }    
  }, [timeout, phase]);

  React.useEffect( () => {
    if (passInfo.password === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: checkPassword(passInfo.password)
        })
      });
    }
  }, [passInfo.password]);


  //checks if the two passwords match
  React.useEffect( () => {
    if (passInfo.confirmPassword === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: passInfo.password === passInfo.confirmPassword
        })
      });
    }
  }, [passInfo.confirmPassword])


  function handleSubmit(event){
    event.preventDefault();

    try{
      sendEmail({
        variables:{
          email: email
        }
      })
    }
    catch(error){
      console.log(error);
    }
  }

  function handleOTP(event){
    event.preventDefault();

    try{
      verifyOtp({
        variables:{
          email: email,
          otp: otp
        }
      })
    }
    catch(error){
      console.log(error);
    }
  }

  function handleInput(event){
    const {name, value} = event.target;
    setPassInfo( prevUser => {
      return(
        {
          ...prevUser,
          [name]: value
        }
      )
    });
  };

  function checkPassword(str){
    const hasNumber = /\d/.test(str);
    const hasLetter = /[a-zA-Z]/.test(str);
    const hasSpecialSymbol = /[^a-zA-Z0-9]/.test(str);
    const minLength = str.length >= 8;

    return hasNumber && hasLetter && hasSpecialSymbol && minLength;
  }

  function handleNewPassword(event){
    event.preventDefault();

    try{
      recoverPassword({
        variables: {
          email: email,
          password: passInfo.password
        }
      })
    }
    catch(error){
      console.log(error);
    }
  }

  return(
    <PageDiv>
      <GreenCircle />
      <BlueCircle />
      <BlackCircle />
        {phase === "email" &&
        <div>
          <Arrow onClick={ () => {
            navigate('/Login');
          }}><img src={back_arrow} />
          </Arrow>
          <PageContainer onSubmit={handleSubmit} mt={'80'}>
            <RecoveryText>
              Recupero <span className="colored">password</span>
            </RecoveryText>
            <TextContainer>
            Inserisci l'email collegata all'account non verificato, ti verrà inviato un codice per email per verificare la tua identità.
            </TextContainer>
            <FormField  
                width={loginInput} 
                name = "Email" 
                pHolder = "Username" 
                inputName = "email" 
                value = {email} 
                handler={handleEmail}
                fontDim="20px"
                inputDim="16px"
                icon={email_icon}
              >
              </FormField>
              <MainButton
                type="submit"
                text="Invia"
                active={valid}
              >
              </MainButton>
              <MarginDiv />
          {emailCorrect && <CorrectPopUp text="Email inviata"/>}
          {emailError && <ErrorPopUp text="Email non corretta"/>}
          </PageContainer>
        </div>
          }
        {phase === "otp" &&
          <PageContainer onSubmit={handleOTP}>
            <OTPInput otp={otp} setOtp={setOtp}/>
            <MainButton
              type="submit"
              text="Verifica"
              active={otpComplete}
            />
            <SendText onClick={handleSubmit}>
              Non hai ricevuto l'email? Prova tra {timeout}
              <SendAgain disabled={timeout} active={timeout === 0}>Invia nuovamente</SendAgain>
            </SendText>
            <MarginDiv />
            {otpError && <ErrorPopUp text="Otp non corretto"/>}
            {otpCorrect && <CorrectPopUp text="Otp corretto"/>}
          </PageContainer>
        }
        {phase === "newPassword" &&
          <PageContainer onSubmit={handleNewPassword}>
            <RecoveryText>
              Nuova <span className="colored">password</span>
            </RecoveryText>
            <TextContainer>
              Crea una nuova password
            </TextContainer>
            <FormField icon={lock} width = {longInput} name = "Password" pHolder = "********" inputName = "password" value = {passInfo.password} handler={handleInput} type="password"></FormField>
            {!validation.validPassword && <ErrorMessage width = {longInput} left={true} text={"La password deve contentere:"} ul={true} ulOptions={["1 numero", "1 carattere speciale", "1 lettera maiuscola", "almeno 8 caratteri"]} />}
            <FormField icon={lock} width = {longInput} name = "Conferma password" pHolder = "********" inputName = "confirmPassword" value = {passInfo.confirmPassword} handler={handleInput} type="password"></FormField>
            {!validation.samePassword && <ErrorMessage width = {longInput} left={true} text="Le password non combaciano" />}
            <MainButton active={passInfo.password && validation.validPassword && passInfo.confirmPassword && validation.samePassword} disabled={passInfo.password && validation.validPassword && passInfo.confirmPassword && validation.samePassword} text="Salva" type="submit" />
            <MarginDiv />
            {passCorrect && <CorrectPopUp text="Password modificata con successo" />}
            {passError && <ErrorPopUp text="Password non salvata" />}

          </PageContainer>

        }
    </PageDiv>
  )
}