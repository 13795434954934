import styled from "styled-components"
import tw from "twin.macro";
import { mainFucsia, mainPurple, secondaryBackground } from "./const";

export const PulsatingCircle = styled.div`
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  background: transparent;
  pointer-events: none;
`;

export const GreenCircle = styled(PulsatingCircle)`
    @keyframes pulsate{
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  top: -140px; /* Adjust as needed */
  right: -140px; /* Adjust as needed */
  width: 280px;
  height: 280px;
  border: 2px solid #006d6d;
  animation: pulsate 10s linear infinite;
`;

export const BlueCircle = styled(PulsatingCircle)`
  top: -90px; /* Adjust as needed */
  right: -70px; /* Adjust as needed */
  width: 180px;
  height: 180px;
  border: 2px solid #00ffff;
  animation: pulsate 8s linear infinite;
`;

export const BlackCircle = styled(PulsatingCircle)`
  top: -120px; /* Adjust as needed */
  left: -120px; /* Adjust as needed */
  width: 240px;
  height: 240px;
  border: 2px solid #004848;
  animation: pulsate 9s linear infinite;
`;

export const FormContainer = styled.div`
  ${tw`
    mt-3
    justify-self-center
    justify-items-center
    flex
    flex-col
    items-center
  `}
  z-index: 5;
`

export const TextContainer = styled.div`
  ${tw`
    mt-3
    flex
    flex-row
    items-center
    text-white
    font-medium
    mb-8
  `}
  font-size: 14px;
`

export const LinkContainer = styled.a`
  ${tw`
      pl-1
  `}
  color: ${mainFucsia};
`

export const FieldContainer = styled.div`
  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      mb-3
  `}
  font-size: ${props => props.fontSize || '16px'};
  width: ${props => props.width};
`;

export const FieldName = styled.p`
  ${tw`
      ml-3
      mt-2
      mb-2
  `}
`;

export const FieldInput = styled.input`
  ${tw`
      text-opacity-50
      font-normal
      py-2
      px-4
      rounded-3xl
  `}

  border-bottom: 1px solid ${mainPurple};
  border-right: 1px solid ${mainPurple};
  border-left: 1px solid ${mainPurple};
  font-size: ${props => props.inputSize || '14px'};
  width: 100%;
  background: ${secondaryBackground};
  transition: border-bottom 0.3s ease-in-out,
              border-left 0.3s ease-in-out,
              border-right 0.3s ease-in-out;



  &:focus {
    border-bottom: 1px solid ${mainFucsia};
    border-right: 1px solid ${mainFucsia};
    border-left: 1px solid ${mainFucsia};
  }
`;

export const FormRow = styled.div`
  ${tw`
    flex
    flex-row
    justify-between
    items-center
  `}
`

export const OptionsContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-start
      mt-3
  `}
  width: 80%;
`
export const OptionWare = styled.div`
  ${tw`
      flex
      items-center
      justify-start
      w-full
      py-3
  `}

  .delete{
    color: red;
  }

  .sell{
    color: #00dada;
  }
`
export const OptionWareIcon = styled.img`
  ${tw`
      mr-2
  `}
  width: 22px;
  height: 22px;
`
export const OptionText = styled.div`
  color: white;
  font-size: 16px;
`