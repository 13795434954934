import { useNavigate } from "react-router-dom";
import styled from "styled-components"
import tw from "twin.macro";
import { longInput } from "../const";
import back_arrow from "../icons/back_arrow.png"
import FormField from "../components/FormField";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import MainButton from "../components/MainButton";
import CorrectPopUp from "../components/CorrectPopUp";
import ErrorPopUp from "../components/ErrorPopUp";

const PageDiv = styled.div`
  position: relative;
  overflow-x: hidden;
`

const Arrow = styled.div`
  width: 30px;
  height: 30px;
  z-index: 2;
`

const RowDiv = styled.div`
    ${tw`
      flex
      items-center
    `}
    margin-top: 30px;
  margin-left: 20px;
`

const RegistrationText = styled.span`
  ${tw`
      text-white
  `}
  width: ${longInput};
  font-size: 25px;
  font-weight: 600;
  margin-left: 15px;
`

const RegistrationContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GET_DATA = gql`
  query{
    getUser{
      firstName
      lastName
      email
    }
  }
`

const CHANGE_USER = gql`
    mutation changeUser($changeInfo: ChangeInfoArgs!) {
      changeUser(changeInfo: $changeInfo)
    }
  `;

const ChangeForm = styled.form`
  margin-top: 20px;
`

const TextContainer = styled.div`
    color: #efefef;
    font-size: 18px;
    font-weight: 500;
    width: ${longInput};
`

export default function ChangeInfoPage(){
  const navigate = useNavigate();

  const {loading: loadUser, data: dataUser} = useQuery(GET_DATA);
  const [user, setUser] = React.useState({
    firstName: '',
    lastName: '',
    email: ''
  })
  const [changeUser, {loading: loadChange, error: errorChange, data: dataChange}] = useMutation(CHANGE_USER);
  const [recordChange, setChange] = React.useState(false);
  const [correct, setCorrect] = React.useState(false);

  React.useEffect( () => {
    if (dataUser){
      setUser({
        firstName: dataUser.getUser.firstName,
        lastName: dataUser.getUser.lastName,
        email: dataUser.getUser.email
      });
    }
  }, [dataUser]);

  React.useEffect( () => {
    if (dataUser){
      if (recordChange && (user.firstName === dataUser.getUser.firstName) && (user.lastName === dataUser.getUser.lastName) && (user.email === dataUser.getUser.email) ) 
        setChange(false);
      if (!recordChange && ((user.firstName !== dataUser.getUser.firstName) || (user.lastName !== dataUser.getUser.lastName) || (user.email !== dataUser.getUser.email)))
        setChange(true);
    }
  }, [user]);

  function handleInput(event){
    const {name, value} = event.target;
    setUser( prevUser => {
      return(
        {
          ...prevUser,
          [name]: value
        }
      )
    });
  };

  function submitChange(event){
    event.preventDefault();

    try{
      changeUser({
        variables: {
          changeInfo: {...user}
        }
      })
      setCorrect(true);
    }
    catch(error){
      console.log(error);
      setCorrect(false);
    }
  }

  return(
    <PageDiv>
      <RowDiv>
        <Arrow onClick={ () => {
          navigate('/profilo');
        }}>
          <img src={back_arrow} />
        </Arrow>
        <RegistrationText>
          Modifica dati
        </RegistrationText>
      </RowDiv>
      { !loadUser &&
          <RegistrationContainer>
            <TextContainer>
              Cambia il valore dei campi e salva
            </TextContainer>
            <ChangeForm onSubmit={submitChange}>
              <FormField width = {longInput} name = "Email"  inputName = "email" value = {user.email} handler={handleInput}></FormField>
              <FormField width = {longInput} name = "Nome" inputName = "firstName" value = {user.firstName} handler={handleInput}></FormField>
              <FormField width = {longInput} name = "Cognome" inputName = "lastName" value = {user.lastName} handler={handleInput}></FormField>
              <MainButton type="submit" text="Salva" active={recordChange} />
              {correct && dataChange && <CorrectPopUp text={dataChange.changeUser} />}
              {!correct && dataChange && <ErrorPopUp text={dataChange.changeUser} />}

            </ChangeForm>
        </RegistrationContainer>
      }
      {
        loadUser &&
          <h2>STO CARICANDO</h2>
      }
    </PageDiv>
  )
}