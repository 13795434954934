import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import './PDFGenerator.css'
import { Doughnut } from 'react-chartjs-2';
import {
  Chart
} from 'chart.js';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import GenerateMonthReport from './GenerateMonthReport';
import { gql, useLazyQuery } from '@apollo/client';
import CSelector from './CSelector';
import { formatDate, getForfettaryTaxes, mapMonth, months, secondaryText, years } from '../const';
import MainButton from './MainButton';
import LoadingAnimation from './LoadingAnimation';
import finix_banner from "../logos/finix_full_whitebg.png"
import tw from 'twin.macro';
import GenerateYearReport from './GenerateYearReport';

const SButton = styled.button`
  color: white;
`

const StyledDiv = styled.div`
  color: black;
`

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  img{
    width: 80%;
    height: auto;
  }
  margin-bottom: 20px;
`

const BarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  img{
    width: 60%;
    height: auto;
  }
  margin-bottom: 20px;
`

const PieContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: -50px;

  img{
    width: 50%;
    height: auto;
  }
  margin-bottom: 10px;
`

const PieTagContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 40px;

  img{
    width: 45%;
    height: auto;
  }
`

const PieValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 50px 0;

  .titlePieValue{
    font-size: 22px;
  }

  .valuePieValue{
    font-size: 24px;
    font-weight: 600;
  }
`

const SelectorRow = styled.div`
  display: flex;
  width: 100%;
  padding-inline: 6%;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const SelectionTitle = styled.div`
  margin-top: 20px;
  color: ${secondaryText};
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
`
const PdfContainer = styled.div`
  position: relative;
`

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  page-break-inside: avoid;
`

const StyledTable = styled.table`
  width: 650px;
  border-collapse: collapse;
  border-spacing: 0;

  .priceC{
    width: 100px;
  }
  
  .nameC{
    max-width: 260px;
    line-height: 1.1;
    word-wrap: break-word;
    padding-inline: 10px;
  }
  
  .typeC{
    width: 130px;
  }
  
  .dateC{
    width: 160px;
  }

  td + .valueTable{
    text-align: left;
    padding-left: 30px;
  }
`

const HeaderRow = styled.tr`
  background-color: black;
  color: white;
`

const TableHeader = styled.th`
  text-align: center;
`

const StyledData = styled.td`
  text-align: center;
`

const TableRow = styled.tr`
  ${props => props.colored ? 'background: lightgrey;' : 'background: white;'}
  color: black;

  .voiceTable{
    text-align: left;
    padding-left: 20px;
  }
`

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35%;

  img{
    width: 50%;
    height: auto;
  }
`

const SelectionContainer = styled.div`
  ${tw`
      flex
      justify-around
      px-6
  `}
  width: 100%;
`

const StyledButton = styled.div`
${tw`
    px-10
    py-2
    rounded-xl 
    text-center
    font-semibold
    mt-5
  `}
  position: relative;
  overflow: hidden;
  font-size: ${props => props.fontSize || '18px'};
  background: transparent;
  ${props => props.fill ? "color: #00dada;" : `color: ${secondaryText};`}
  ${props => props.fill ? "border: 1px solid #00dada;" : `border: 1px solid ${secondaryText};`}
`

const MONTH_DATA = gql`
  query getMonthlyReport($month: Float!, $year: Float!){
    getMonthlyReport(month: $month, year: $year){
      barSales{
        total
        type
      }
      salesByTags{
        tagText
        total
      }
      barPurchases{
        total
        type
      }
      itemsSold{
        name
        type
        buyPrice
        sellDate
        sellPrice
      }
      itemsPurchased{
        name
        type
        buyDate
        buyPrice
      }
      totalSales{
        label
        data
      }
      totalExpense{
        label
        data
      }
    }
  }
`

const YEAR_DATA = gql`
query getYearlyReport($year: Float!){
  getYearlyReport(year: $year){
    barSales{
      total
      type
    }
    salesByTags{
      tagText
      total
    }
    barPurchases{
      total
      type
    }
    itemsSold{
      name
      type
      buyPrice
      sellDate
      sellPrice
    }
    itemsPurchased{
      name
      type
      buyDate
      buyPrice
    }
    totalSales{
      label
      data
    }
    totalExpense{
      label
      data
    }
    totalSalesPrediction
  }
}
`

export default function PDFGenerator(){
  const [getMonthlyReport, {loadingMonth, data: monthData, errorMonth}] = useLazyQuery(MONTH_DATA, {
    onError: (error) => {
      console.log(error);
    }
  });

  const [getYearlyReport, {loadingYear, data: yearData, errorData}] = useLazyQuery(YEAR_DATA, {
    onError: (error) => {
      console.log(error);
    }
  });
  
  const itemsPerPage = 22;
  const [month, setMonth] = useState(months[0]);
  const [year, setYear] = useState(years()[0]);

  const [linePurImage, SetLinePurImage] = useState(null);
  const [lineSalImage, SetLineSalImage] = useState(null);
  const [barPurImage, SetBarPurImage] = useState(null);
  const [barSalImage, SetBarSalImage] = useState(null);
  const [pieSalImage, SetPieSalImage] = useState(null);
  const [pieTagImage, setPieTagImage] = useState(null);

  const [soldItems, setSoldItems] = React.useState();
  const [purchasedItems, setPurchased] = React.useState();

  const [lineSales, setLineSales] = React.useState();
  const [linePurchases, setLinePurchases] = React.useState();

  const [salesBar, setSalesBar] = React.useState();
  const [purchasesBar, setPurchasesBar] = React.useState();

  const [istoSales, setIstoSales] = React.useState();
  const [istoPurchases, setIstoPurchases] = React.useState();

  const [pieTagsData, setPieTags] = React.useState();

  const[startDownload, setDownload] = React.useState(false);
  const [generate, setGenerate] = React.useState(false);

  const [totalSales, setTotalSales] = React.useState(0);
  const [totalPurchases, setTotalPurchases] = React.useState(0);
  const [totalGains, setTotalGains] = React.useState(0);

  const [prediction, setPrediction] = React.useState({
    totalSalesPrediction: 0
  });
  const [taxes, setTaxes] = React.useState(0);
  const [contributiToPay, setContributi] = React.useState(0);
  const [finalYearValues, setFinalYear] = React.useState({
    grossRemaining: 0,
    totExpense: 0,
    netIncome: 0
  })


  const handleReportDownload = (barPurImage, linePurImage, barSalImage, lineSalImage, pieSalImage, tagImagePie) => {
    SetLinePurImage(linePurImage);
    SetLineSalImage(lineSalImage);
    SetBarPurImage(barPurImage);
    SetBarSalImage(barSalImage);
    SetPieSalImage(pieSalImage);
    setPieTagImage(tagImagePie)
    setDownload(true);
  };

  React.useEffect( ( ) => {
    if(monthData){
      const {barSales, barPurchases, itemsSold, itemsPurchased, totalSales, totalExpense, salesByTags} = monthData.getMonthlyReport;
      setSoldItems([...itemsSold]);
      setPurchased([...itemsPurchased]);
      setLineSales(totalSales);
      setLinePurchases(totalExpense);
      setSalesBar(barSales);
      setPurchasesBar(barPurchases);
      setPieTags([...salesByTags]);
      setTotalSales(barSales.reduce((acc, obj) => acc+obj.total, 0));
      setTotalPurchases(barPurchases.reduce((acc, obj) => acc+obj.total, 0));
      setTotalGains(itemsSold.reduce( (acc, obj) => acc + (obj.sellPrice - obj.buyPrice), 0))
      setGenerate(true);
    }
  }, [monthData])

  React.useEffect( ( ) => {
    if(yearData){
      const {barSales, barPurchases, itemsSold, itemsPurchased, totalSales, totalExpense, salesByTags, totalSalesPrediction} = yearData.getYearlyReport;
      setSoldItems([...itemsSold]);
      setPurchased([...itemsPurchased]);
      setLineSales(totalSales);
      setLinePurchases(totalExpense);
      setSalesBar(barSales);
      setPurchasesBar(barPurchases);
      setTotalSales(barSales.reduce((acc, obj) => acc+obj.total, 0));
      setTotalPurchases(barPurchases.reduce((acc, obj) => acc+obj.total, 0));
      setTotalGains(itemsSold.reduce( (acc, obj) => acc + (obj.sellPrice - obj.buyPrice), 0));
      setPieTags([...salesByTags]);
      setPrediction({
        totalSalesPrediction: totalSalesPrediction
      });
      const {taxesToPay, contributi} = getForfettaryTaxes(totalSalesPrediction, 1);
      setTaxes(taxesToPay);
      setContributi(contributi);
      setFinalYear({
        grossRemaining: totalSalesPrediction - taxesToPay - contributi,
        totExpense: totalExpense.reduce((acc, obj) => acc + (obj.data), 0),
        netIncome: totalSalesPrediction - taxesToPay - contributi - totalExpense.reduce((acc, obj) => acc + (obj.data), 0),
      })
      setGenerate(true);
    }
  }, [yearData])
  
  const pdfMonth = () => {


    return (
      <>
      <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <BannerContainer>
              <img src={finix_banner} />
            </BannerContainer>
            <StyledDiv className="reportTitle">
              Report mensile
            </StyledDiv>
            <div className="secondTitle">
              Esplora dettagliati report con grafici, dati e tabelle personalizzate sui tuoi acquisti, vendite e guadagni. Monitora il tuo impatto finanziario e celebra i tuoi successi con chiarezza e precisione.
            </div>
          </div>
        </div>
        </div>
        </page>
        <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <div className="contentContainer">
              <div className="contentTitle" style={{marginTop: '50px;'}}>Contenuti</div>
              <div className="contentList">
                <div className="mainContent">
                  <span className="listNumber">1</span>
                  <a className="mainLink">Analisi</a>
                  <div className="dots"></div>
                  <span className="pageNumber">2</span>
                </div>
                <div className="subContentContainer">
                  <div className="subContent">
                    <span className="listNumber">1.1</span>
                    <a className="mainLink">Grafici e dati vendite</a>
                    <span className="pageNumber">2</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.2</span>
                    <a className="mainLink">Vendite nel periodo</a>
                    <span className="pageNumber">2</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.3</span>
                    <a className="mainLink">Vendite per tipo</a>
                    <span className="pageNumber">2</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.4</span>
                    <a className="mainLink">Vendite per tag</a>
                    <span className="pageNumber">2</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.5</span>
                    <a className="mainLink">Tabella vendite per tag</a>
                    <span className="pageNumber">2</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.6</span>
                    <a className="mainLink">Tabella vendite</a>
                    <span className="pageNumber">3</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.7</span>
                    <a className="mainLink">Grafici e dati acquisti</a>
                    <span className="pageNumber">4</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.8</span>
                    <a className="mainLink">Acquisti nel periodo</a>
                    <span className="pageNumber">4</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.9</span>
                    <a className="mainLink">Acquisti per tipo</a>
                    <span className="pageNumber">4</span>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.10</span>
                    <div className="mainLink">Tabella acquisti</div>
                  </div>
                </div>
                <div className="mainContent">
                  <span className="listNumber">2</span>
                  <a className="mainLink">Valori riassuntivi</a>
                </div>
                </div>
              </div>
          </div>
        </div>
        </div>
        </page>
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                <div className="sectionTitle">
                  Analisi
                </div>
                <div className="secondaryTitle">
                  Grafici e dati vendite
                </div>
                <div className="sectionDesc">
                  Di seguito potrai visualizzare tutte le statistiche e i dati sulle tue vendite. Il grafico a linea mostra l'andamento delle tue vendite nel periodo. Il grafico a torta mostra la suddivisione in percentuale tra le varie tipologie di oggetti. In fondo la tabella che riporta tutte le vendite effettuate. <br/>
                </div>
                {lineSalImage && <div className="sectionSubtitle">
                  Vendite nel periodo
                </div>}
                {lineSalImage && 
                  <LineContainer>
                    <img className="chartImg" src={lineSalImage} />
                  </LineContainer>}
                  {barSalImage && <div className="sectionSubtitle">
                    Vendite per tipo
                  </div>}
                  {barSalImage && 
                    <BarContainer height={salesBar.length}>
                      <img className="chartImg" src={barSalImage} />
                    </BarContainer>
              }
              </div>
            </div>
            
              </div>
            </div>
          </div>
        </page>
        {pieTagsData.length > 0 && <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                {pieTagImage && <div className="sectionSubtitle">
                  Vendite per tag
                </div>}
                {pieTagImage && 
                  <PieTagContainer>
                    <img className="chartImg" src={pieTagImage} />
                  </PieTagContainer>
              }
              </div>
              {pieTagImage && <div className="sectionSubtitle">
                    Tabella vendite per tag
                  </div>}
          {pieTagsData && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome tag</TableHeader>
                      <TableHeader className="typeC">Totale Vendite</TableHeader>
                    </HeaderRow>
                    {pieTagsData.slice(0,10).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.tagText}</StyledData>
                        <StyledData className="typeC">€{item.total}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
            </div>
            
              </div>
            </div>
          </div>
        </page>}
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
                <div className="reportBody">
                {barSalImage && <div className="sectionSubtitle">
                    Tabella vendite
                  </div>}
                  {soldItems && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Tipo</TableHeader>
                      <TableHeader className="dateC">Data vendita</TableHeader>
                      <TableHeader className="priceC">Vendita</TableHeader>
                    </HeaderRow>
                    {soldItems.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.type}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                    {soldItems.length <= itemsPerPage && <TableRow colored={soldItems.length % 2 != 0}>
                      <StyledData className="nameC"><b>Totale</b></StyledData>
                      <StyledData className="typeC"></StyledData>
                      <StyledData className="dateC"></StyledData>
                      <StyledData className="priceC"><b>€{soldItems.reduce((acc, obj) => acc + obj.sellPrice, 0)}</b></StyledData>
                    </TableRow>}
                  </StyledTable>
                </TableContainer>}
                </div>
              </div>
            </div>
          </div>
        </page>
        {extraTables(soldItems, false)}
        <page>
        <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                <div className="secondaryTitle">
                  Grafici e dati acquisto
                </div>
                <div className="sectionDesc">
                  Di seguito potrai visualizzare tutte le statistiche e i dati sui tuoi acquisti. Il grafico a linea mostra l'andamento dei tuoi acquisti nel periodo. L'istogramma mostra la suddivisione tra le varie tipologie di oggetti. In fondo la tabella che riporta tutti gli acquisti effettuati. <br/>
                </div>
                {lineSalImage && <div className="sectionSubtitle">
                  Acquisti nel periodo
                </div>}
                {linePurImage && 
                  <LineContainer>
                    <img className="chartImg" src={linePurImage} />
                  </LineContainer>}
                  {barPurImage && <div className="sectionSubtitle">
                    Acquisti per tipo
                  </div>}
                  {barPurImage && 
                    <BarContainer height={salesBar.length}>
                      <img className="chartImg" src={barPurImage} />
                    </BarContainer>
              }
              </div>
            </div>
            
              </div>
            </div>
          </div>
        </page>
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
                <div className="reportBody">
                {barSalImage && <div className="sectionSubtitle">
                    Tabella acquisti
                  </div>}
          {purchasedItems && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Tipo</TableHeader>
                      <TableHeader className="dateC">Data acquisto</TableHeader>
                      <TableHeader className="priceC">Acquisto</TableHeader>
                    </HeaderRow>
                    {purchasedItems.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.type}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                    {purchasedItems.length <= itemsPerPage && <TableRow colored={purchasedItems.length % 2 != 0}>
                      <StyledData className="nameC"><b>Totale</b></StyledData>
                      <StyledData className="typeC"></StyledData>
                      <StyledData className="dateC"></StyledData>
                      <StyledData className="priceC"><b>€{purchasedItems.reduce((acc, obj) => acc + obj.buyPrice, 0)}</b></StyledData>
                    </TableRow>}
                  </StyledTable>
                </TableContainer>}
                </div>
              </div>
            </div>
          </div>
        </page>
        {extraTables(purchasedItems, true)}
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
                <div className="reportBody">
                  <div className="sectionSubtitle" style={{margin: '50px 0px 0px 0px;'}}>
                    Tabella riassuntiva
                  </div>
                  <TableContainer>
                    <StyledTable>
                      <HeaderRow>
                        <TableHeader>Acquisti</TableHeader>
                        <TableHeader>Vendite</TableHeader>
                        <TableHeader>Liquidità</TableHeader>
                        <TableHeader>Guadagno</TableHeader>
                        <TableHeader>Guadagno %</TableHeader>
                      </HeaderRow>
                      <TableRow colored={false}>
                          <StyledData><b>€{totalPurchases}</b></StyledData>
                          <StyledData><b>€{totalSales}</b></StyledData>
                          <StyledData><b>€{totalSales - totalPurchases}</b></StyledData>
                          <StyledData><b>€{totalGains}</b></StyledData>
                          <StyledData><b>+{Math.floor(totalGains/totalPurchases * 100)}%</b></StyledData>
                        </TableRow>
                    </StyledTable>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </page>
      </>
    )
  }

  const pdfYear = () => {


    return (
      <>
      <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <BannerContainer>
              <img src={finix_banner} />
            </BannerContainer>
            <StyledDiv className="reportTitle">
              Report annuale
            </StyledDiv>
            <div className="secondTitle">
              Esplora dettagliati report con grafici, dati e tabelle personalizzate sui tuoi acquisti, vendite e guadagni. Monitora il tuo impatto finanziario e celebra i tuoi successi con chiarezza e precisione.
            </div>
          </div>
        </div>
      </div>
      </page>
      <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <div className="contentContainer">
              <div className="contentTitle" style={{marginTop: '50px;'}}>Contenuti</div>
              <div className="contentList">
                <div className="mainContent">
                  <span className="listNumber">1</span>
                  <a className="mainLink">Analisi</a>
                  <div className="dots"></div>
                </div>
                <div className="subContentContainer">
                  <div className="subContent">
                    <span className="listNumber">1.1</span>
                    <a className="mainLink">Grafici e dati vendite</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.2</span>
                    <a className="mainLink">Vendite nel periodo</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.3</span>
                    <a className="mainLink">Vendite per tipo</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.4</span>
                    <a className="mainLink">Vendite per tag</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.5</span>
                    <a className="mainLink">Tabella vendite per tag</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.6</span>
                    <a className="mainLink">Tabella vendite</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.7</span>
                    <a className="mainLink">Grafici e dati acquisti</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.8</span>
                    <a className="mainLink">Acquisti nel periodo</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.9</span>
                    <a className="mainLink">Acquisti per tipo</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">1.10</span>
                    <a className="mainLink">Tabella acquisti</a>
                  </div>
                </div>
                <div className="mainContent">
                  <span className="listNumber">2</span>
                  <a className="mainLink">Previsioni e indicazioni sulla tassazione</a>
                </div>
                <div className="subContentContainer">
                  <div className="subContent">
                    <span className="listNumber">2.1</span>
                    <a className="mainLink">Previsioni fine anno</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">2.2</span>
                    <a className="mainLink">Calcolo tasse</a>
                  </div>
                  <div className="subContent">
                    <span className="listNumber">2.2</span>
                    <a className="mainLink">Vendite e guadagni finali</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </page>
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                <div className="sectionTitle">
                  Analisi
                </div>
                <div className="secondaryTitle">
                  Grafici e dati vendite
                </div>
                <div className="sectionDesc">
                  Di seguito potrai visualizzare tutte le statistiche e i dati sulle tue vendite. Il grafico a linea mostra l'andamento delle tue vendite nel periodo. Il grafico a torta mostra la suddivisione in percentuale tra le varie tipologie di oggetti. In fondo la tabella che riporta tutte le vendite effettuate. <br/>
                </div>
                {lineSalImage && <div className="sectionSubtitle">
                  Vendite nel periodo
                </div>}
                {lineSalImage && 
                  <LineContainer>
                    <img className="chartImg" src={lineSalImage} />
                  </LineContainer>}
                  {barSalImage && <div className="sectionSubtitle">
                    Vendite per tipo
                  </div>}
                  {barSalImage && 
                    <BarContainer height={salesBar.length}>
                      <img className="chartImg" src={barSalImage} />
                    </BarContainer>
              }
              </div>
            </div>
            
              </div>
            </div>
          </div>
        </page>
        {pieTagsData.length > 0 && <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                {pieTagImage && <div className="sectionSubtitle">
                  Vendite per tag
                </div>}
                {pieTagImage && 
                  <PieTagContainer>
                    <img className="chartImg" src={pieTagImage} />
                  </PieTagContainer>
              }
              </div>
              {pieTagImage && <div className="sectionSubtitle">
                    Tabella vendite per tag
                  </div>}
          {pieTagsData && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome tag</TableHeader>
                      <TableHeader className="typeC">Totale Vendite</TableHeader>
                    </HeaderRow>
                    {pieTagsData.slice(0,10).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.tagText}</StyledData>
                        <StyledData className="typeC">€{item.total}</StyledData>
                      </TableRow>
                    ))}
                  </StyledTable>
                </TableContainer>}
            </div>
            
              </div>
            </div>
          </div>
        </page>}
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
                <div className="reportBody">
                {barSalImage && <div className="sectionSubtitle">
                    Tabella vendite
                  </div>}
          {soldItems && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Tipo</TableHeader>
                      <TableHeader className="dateC">Data vendita</TableHeader>
                      <TableHeader className="priceC">Vendita</TableHeader>
                    </HeaderRow>
                    {soldItems.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.type}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                    {soldItems.length <= itemsPerPage && <TableRow colored={soldItems.length % 2 != 0}>
                      <StyledData className="nameC"><b>Totale</b></StyledData>
                      <StyledData className="typeC"></StyledData>
                      <StyledData className="dateC"></StyledData>
                      <StyledData className="priceC"><b>€{soldItems.reduce((acc, obj) => acc + obj.sellPrice, 0)}</b></StyledData>
                    </TableRow>}
                  </StyledTable>
                </TableContainer>}
                </div>
              </div>
            </div>
          </div>
        </page>
        {extraTables(soldItems, false)}
        <page>
        <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
              <div className="reportBody">
              <div className="section">
                <div className="secondaryTitle">
                  Grafici e dati acquisto
                </div>
                <div className="sectionDesc">
                  Di seguito potrai visualizzare tutte le statistiche e i dati sui tuoi acquisti. Il grafico a linea mostra l'andamento dei tuoi acquisti nel periodo. L'istogramma mostra la suddivisione tra le varie tipologie di oggetti. In fondo la tabella che riporta tutti gli acquisti effettuati. <br/>
                </div>
                {lineSalImage && <div className="sectionSubtitle">
                  Acquisti nel periodo
                </div>}
                {linePurImage && 
                  <LineContainer>
                    <img className="chartImg" src={linePurImage} />
                  </LineContainer>}
                  {barPurImage && <div className="sectionSubtitle">
                    Acquisti per tipo
                  </div>}
                  {barPurImage && 
                    <BarContainer>
                      <img className="chartImg" src={barPurImage} />
                    </BarContainer>
              }
              </div>
            </div>
            
              </div>
            </div>
          </div>
        </page>
        <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
                <div className="reportBody">
                {barSalImage && <div className="sectionSubtitle">
                    Tabella acquisti
                  </div>}
          {purchasedItems && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Tipo</TableHeader>
                      <TableHeader className="dateC">Data acquisto</TableHeader>
                      <TableHeader className="priceC">Acquisto</TableHeader>
                    </HeaderRow>
                    {purchasedItems.slice(0, itemsPerPage).map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.type}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                    {purchasedItems.length <= itemsPerPage && <TableRow colored={purchasedItems.length % 2 != 0}>
                      <StyledData className="nameC"><b>Totale</b></StyledData>
                      <StyledData className="typeC"></StyledData>
                      <StyledData className="dateC"></StyledData>
                      <StyledData className="priceC"><b>€{purchasedItems.reduce((acc, obj) => acc + obj.buyPrice, 0)}</b></StyledData>
                    </TableRow>}
                  </StyledTable>
                </TableContainer>}
                </div>
              </div>
            </div>
          </div>
        </page>
        {extraTables(purchasedItems, true)}
        <page>
        <div className="bodyClass">
          <div className="container">
            <div className="paddingContainer">
              <div className="reportBody">
                <div className="section">
                  <div className="sectionTitle" style={{marginTop: '-20px;'}}>
                    Indicazioni sulla tassazione
                  </div>
                  <div className="secondaryTitle">
                    Calcolo tasse
                  </div>
                  <div className="sectionDesc">
                    Secondo le leggi riguardo il regime forfettario, le tasse verranno calcolate come segue in tabella.
                  </div>
                  <TableContainer>
                    <StyledTable>
                      <HeaderRow>
                        <TableHeader className="voiceTable">Voce</TableHeader>
                        <TableHeader className="valueTable">Valore</TableHeader>
                      </HeaderRow>
                      <TableRow colored={0 % 2 != 0}>
                        <StyledData className="voiceTable">Previsione vendite</StyledData>
                        <StyledData className="valueTable">+ €{prediction.totalSalesPrediction}</StyledData>
                      </TableRow>
                      <TableRow colored={1 % 2 != 0}>
                        <StyledData className="voiceTable">Tasse da versare</StyledData>
                        <StyledData className="valueTable">- €{taxes}</StyledData>
                      </TableRow>
                      <TableRow colored={2 % 2 != 0}>
                        <StyledData className="voiceTable">Contributi da versare</StyledData>
                        <StyledData className="valueTable">- €{contributiToPay}</StyledData>
                      </TableRow>
                      <TableRow colored={3 % 2 != 0}>
                        <StyledData className="voiceTable"><b>Lordo rimanente</b></StyledData>
                        <StyledData className="valueTable"><b>+ €{finalYearValues.grossRemaining}</b></StyledData>
                      </TableRow>
                    </StyledTable>
                  </TableContainer>
                  <div className="secondaryTitle">
                    Vendite e guadagni finali
                  </div>
                  <TableContainer>
                    <StyledTable>
                      <HeaderRow>
                        <TableHeader className="voiceTable">Voce</TableHeader>
                        <TableHeader className="valueTable">Valore</TableHeader>
                      </HeaderRow>
                      <TableRow colored={0 % 2 != 0}>
                        <StyledData className="voiceTable">Lordo rimanente</StyledData>
                        <StyledData className="valueTable">+ €{finalYearValues.grossRemaining}</StyledData>
                      </TableRow>
                      <TableRow colored={2 % 2 != 0}>
                        <StyledData className="voiceTable">Costi d'acquisto</StyledData>
                        <StyledData className="valueTable">- €{finalYearValues.totExpense}</StyledData>
                      </TableRow>
                      <TableRow colored={3 % 2 != 0}>
                        <StyledData className="voiceTable"><b>Guadagno totale annuale</b></StyledData>
                        <StyledData className="valueTable"><b>+ €{finalYearValues.netIncome}</b></StyledData>
                      </TableRow>
                    </StyledTable>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
        </page>
      </>
    )
  }

  const extraTables = (items, purchases) => {
    var additionalPages = [];
    for( let i = itemsPerPage; i < items.length; i += itemsPerPage){
      const currentItems = items.slice(i, i + itemsPerPage);
      if (purchases){
        additionalPages.push(renderExtraPurTable(currentItems, i + itemsPerPage >= items.length))
      }
      else{
        additionalPages.push(renderExtraSellTable(currentItems, i + itemsPerPage >= items.length))
      }
    }

    return(
      <>
        {additionalPages}
      </>
    )
  }

  const renderExtraPurTable = (items, includeTotal) => (
    <page>
      <div className="bodyClass">
        <div className="container">
          <div className="paddingContainer">
            <div className="reportBody">
              {barSalImage && (
                <div className="sectionSubtitle">Tabella acquisti</div>
              )}
              {items && (
                <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Tipo</TableHeader>
                      <TableHeader className="dateC">Data acquisto</TableHeader>
                      <TableHeader className="priceC">Acquisto</TableHeader>
                    </HeaderRow>
                    {items.map((item, index) => (
                      <TableRow key={index} colored={index % 2 !== 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.type}</StyledData>
                        <StyledData className="dateC">{formatDate(item.buyDate)}</StyledData>
                        <StyledData className="priceC">€{item.buyPrice}</StyledData>
                      </TableRow>
                    ))}
                    {includeTotal && <TableRow colored={items.length % 2 !== 0}>
                      <StyledData className="nameC">
                        <b>Totale</b>
                      </StyledData>
                      <StyledData className="typeC"></StyledData>
                      <StyledData className="dateC"></StyledData>
                      <StyledData className="priceC">
                        <b>€{purchasedItems.reduce((acc, obj) => acc + obj.buyPrice, 0)}</b>
                      </StyledData>
                    </TableRow>}
                  </StyledTable>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </page>
  )

  const renderExtraSellTable = (items, includeTotal) => (
    <page>
          <div className="bodyClass">
            <div className="container">
              <div className="paddingContainer">
                <div className="reportBody">
                {barSalImage && <div className="sectionSubtitle">
                    Tabella vendite
                  </div>}
          {items && <TableContainer>
                  <StyledTable>
                    <HeaderRow>
                      <TableHeader className="nameC">Nome</TableHeader>
                      <TableHeader className="typeC">Tipo</TableHeader>
                      <TableHeader className="dateC">Data vendita</TableHeader>
                      <TableHeader className="priceC">Vendita</TableHeader>
                    </HeaderRow>
                    {items.map( (item, index) => (
                      <TableRow key={index} colored={index % 2 != 0}>
                        <StyledData className="nameC">{item.name}</StyledData>
                        <StyledData className="typeC">{item.type}</StyledData>
                        <StyledData className="dateC">{formatDate(item.sellDate)}</StyledData>
                        <StyledData className="priceC">€{item.sellPrice}</StyledData>
                      </TableRow>
                    ))}
                    {includeTotal && <TableRow colored={items.length % 2 != 0}>
                      <StyledData className="nameC"><b>Totale</b></StyledData>
                      <StyledData className="typeC"></StyledData>
                      <StyledData className="dateC"></StyledData>
                      <StyledData className="priceC"><b>€{soldItems.reduce((acc, obj) => acc + obj.sellPrice, 0)}</b></StyledData>
                    </TableRow>}
                  </StyledTable>
                </TableContainer>}
                </div>
              </div>
            </div>
          </div>
        </page>
  )

  const printHandler = () => {
    const printElement = ReactDOMServer.renderToString(reportPeriod == "mensile" ? pdfMonth() : pdfYear());

    html2pdf().from(printElement).save();
    setTimeout(() => {
      setGenerate(false);
    }, 1500)

  }

  React.useEffect( () => {
    if(startDownload){
      printHandler();
    }
  },[startDownload]);

  function fetchData(){
    if (reportPeriod === "mensile"){
      try{
        getMonthlyReport({
          variables: {
            month: mapMonth(month),
            year: parseInt(year)
          }
        });
      } catch(error){
        console.log(error);
      }
    } else if (reportPeriod === "annuale"){
      try{
        getYearlyReport({
          variables:{
            year: parseInt(year)
          }
        })
      } catch(error){
        console.log(error);
      }
    }
  }

  const [reportPeriod, setReportPeriod] = React.useState(null);


  return (
    <PdfContainer>
      <SelectionTitle>
        Seleziona il periodo del report
      </SelectionTitle>
      <SelectionContainer>
        <StyledButton fontSize={"16px;"} fill={reportPeriod == "mensile"} onClick={() => setReportPeriod("mensile")}>Mensile</StyledButton>
        <StyledButton fontSize={"16px;"} fill={reportPeriod == "annuale"} onClick={() => setReportPeriod("annuale")}>Annuale</StyledButton>
      </SelectionContainer>
      <SelectionTitle>
        Seleziona {reportPeriod === "mensile" && "mese e"} anno
      </SelectionTitle>
      <SelectorRow>
        {reportPeriod == "mensile" && <CSelector options={months} val={month} func={setMonth} />}
        <CSelector options={years()} val={year} func={setYear} />
      </SelectorRow>
      <MainButton text="Genera report" active={true} onClickFunction={fetchData} />
      {!loadingMonth ?   
        (monthData && generate && monthData.getMonthlyReport && (!linePurImage || !lineSalImage) && reportPeriod == "mensile" && 
        
        <GenerateMonthReport 
          lineDataSales={lineSales} 
          lineDataPurchases={linePurchases} 
          barSales={salesBar}
          barPurchases={purchasesBar}
          tagSales={pieTagsData}
          onImageDownload={handleReportDownload}
          />) :
        <LoadingAnimation />
    }
    {!loadingYear?  
      
      (yearData && generate && yearData.getYearlyReport && (!linePurImage || !lineSalImage) && reportPeriod == "annuale" && 
      
      <GenerateYearReport 
        lineDataSales={lineSales} 
        lineDataPurchases={linePurchases} 
        barSales={salesBar}
        barPurchases={purchasesBar}
        tagSales={pieTagsData}
        onImageDownload={handleReportDownload}
        />) :
      <LoadingAnimation />
    
  }
    </PdfContainer>
  );
}

//<GenerateDoughnutChart lineDataSales={lineSales} onImageDownload={handleReportDownload}/>