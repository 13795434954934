import styled from "styled-components"
import tw from "twin.macro";
import { mainBackground } from "../const";

const VisibilityButton = styled.button`
  ${tw`
      text-white
      font-medium
      items-center
      justify-center
  `}
  font-size: 12px;
  background-color: ${mainBackground};
  width: 100px;
  height: 30px;
  border-radius: 15px;
  margin: 0px 10px;
  border: 1px solid black;
`

export default function SeeButton(props){
  const {functionality, text} = props;
  return(
    <VisibilityButton onClick={functionality}>
      {text}
    </VisibilityButton>
  )
}