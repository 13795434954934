import Chart from 'chart.js/auto';
import React, { useCallback, useRef } from 'react';
import { Doughnut, Line, Bar, Pie } from 'react-chartjs-2';
import styled from "styled-components"
import MainButton from './MainButton';
import { mainBackground, monthFromLabel } from '../const';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);


const GeneratorContainer = styled.div`
  margin-top: 15px;
`
const ChartContainer = styled.div`
  margin-top: 30px;
  opacity: 0;
`

export default function GenerateYearReport(props) {
  const {lineDataSales, lineDataPurchases, barSales, barPurchases, onImageDownload, tagSales} = props;


  let linePurRef = useRef(null);
  let barPurRef = useRef(null);
  let lineSalRef = useRef(null);
  let barSalRef = useRef(null);
  let pieTagRef = useRef(null);
  

  const downloadImage = useCallback(() => {
    const barPurImage = barPurRef.current.toBase64Image();
    const linePurImage = linePurRef.current.toBase64Image();
    const barSalImage = barSalRef.current.toBase64Image();
    const lineSalImage = lineSalRef.current.toBase64Image();
    const pieTagImage = pieTagRef.current.toBase64Image();
    onImageDownload(barPurImage, linePurImage, barSalImage, lineSalImage, null, pieTagImage);
  }, [onImageDownload]);

  const colors = ['#33ccff', '#6600cc', '#967bb6','#708090','#aa0000' ,'#008080'];

  const dataIstogramPurchases = {
    labels: lineDataPurchases? monthFromLabel(lineDataPurchases.map( (obj) => obj.label)) : [],
    datasets: [{
      labels: 'Sales',
      data: lineDataPurchases? lineDataPurchases.map( (obj) => obj.data) : [],
      backgroundColor: '#00dada44',
      borderColor: '#00dada',
      borderWidth: 1
    }]
  };

  const dataIstogramSales = {
    labels: lineDataSales? monthFromLabel(lineDataSales.map( (obj) => obj.label)) : [],
    datasets: [{
      labels: 'Sales',
      data: lineDataSales? lineDataSales.map( (obj) => obj.data) : [],
      backgroundColor: '#00dada44',
      borderColor: '#00dada',
      borderWidth: 1
    }]
  };

  const tagColors = ["#336699", "#66B2FF", "#99CCFF", "#336688", "#66CCFF", "#66A3FF", "#6699CC", "#738FA3", "#99AACC", "#7385A8"]


  const pieDataTags = {
    labels: tagSales ? tagSales.map( (obj) => obj.tagText) : [],
    datasets: [{
      labels: 'Sales',
      data: tagSales? tagSales.map( (obj) => obj.total) : [],
      backgroundColor: tagSales ? tagColors.slice(0, tagSales.length) : 'black',
      borderColor: 'white',
      borderWidth: 2
    }]
  }

  const pieTagOptions = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: 'black',
          boxWidth: 15,
          font: {
            size: 18
          }
        }
      },
      datalabels: {
        display: false
      }
    } 
  }

  const pieOptions = {
    plugins: {
      legend: {
        position: "left",
        labels: {
          color: 'black',
          boxWidth: 10
        }
      },
      datalabels: {
        anchor: 'end', // Set the anchor point to 'end' (top of the bar)
        align: 'end', // Align the labels to the end of the bar
        color: 'black', // Label text color
        
        formatter: (value, context) => {
          return value > 0 ? `€${value}` : null; // Display the value on top of the bar
        },
        font : {
          size: 16
        }
      },
    },
    layout: {
      padding: {
        top: 70, // Adjust the top padding to create space for labels
        bottom: 70,
        left: 10,
        right: 70
      },
    },
  }

  const optionsIstogram = {
    plugins: {
      legend: false,
      datalabels: {
        anchor: 'end', // Set the anchor point to 'end' (top of the bar)
        align: 'end', // Align the labels to the end of the bar
        color: 'black', // Label text color
        
        formatter: (value, context) => {
          return value > 0 ? `€${value}` : null; // Display the value on top of the bar
        },
        font : {
          size: 10
        },
        offset: -2,
      },
    },
    layout: {
      padding: {
        top: 20, // Adjust the top padding to create space for labels
        right: 40
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        }
      },
      y: {
        maxTicksLimit: 2,
        suggestedMin: 0,
        grid: {
          color: '#eeeeee',
          tickWidth: 1,
        },
        ticks: {
          color: 'grey'
        }
      }
    }
  }


  const barDataSales = {
    labels: barSales ? barSales.map( obj => obj.type) : [],
    datasets: [
      {
        label: 'Dataset 1',
        backgroundColor: ['#00dada', '#006d6d', '#001818'],
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: barSales ? barSales.map( obj => obj.total) : [],
        border: 0,
      },
    ],
  };

  const barDataPurchases = {
    labels: barPurchases ? barPurchases.map( obj => obj.type) : [],
    datasets: [
      {
        label: 'Dataset 1',
        backgroundColor: ['#00dada', '#006d6d', '#001818'],
        data: barPurchases ? barPurchases.map( obj => obj.total) : [],
        border: 0,
      },
    ],
  };

  const barOptions = {
    categoryPercentage: 1.05, // here 
    barPercentage: 1.0,  // here
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    layout: {
      padding: {
        right: 60, // Adjust the top padding to create space for labels
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      datalabels: {
        anchor: 'end', // Set the anchor point to 'end' (top of the bar)
        align: 'end', // Align the labels to the end of the bar
        color: 'black', // Label text color
        formatter: (value, context) => {
          return `€${value}`; // Display the value on top of the bar
        },
        offset: 5,
      },
    },
    scales: {
      x: {
        display: false, // Hide the x-axis
      },
      y: {
        beginAtZero: true,
        grid:{
          display: false
        }
      }
    }
  };


  return(
    <GeneratorContainer>
      <MainButton active={true} text={"Scarica report"} onClickFunction={downloadImage}/>
      <ChartContainer>
        {barSales && <Bar ref={barSalRef} data={barDataSales} options={barOptions}/>}
        {lineDataSales && <Bar ref={lineSalRef} data={dataIstogramSales} options={optionsIstogram}/>}
        {barPurchases && <Bar ref={barPurRef} data={barDataPurchases} options={barOptions}/>}
        {lineDataPurchases && <Bar ref={linePurRef} data={dataIstogramPurchases} options={optionsIstogram}/>}
        {tagSales && <Pie ref={pieTagRef} data={pieDataTags} options={pieTagOptions}/>}
      </ChartContainer>
    </GeneratorContainer>
  )
}