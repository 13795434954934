import styled from "styled-components"
import tw from "twin.macro";

const TypeContainer = styled.div`
  ${tw`
      flex
      flex-row
      items-center
      justify-center
      mb-3
  `}
  width: 30%;
  padding: 8px 0px;
  border-radius: 10px;
  font-size: 14px;
  background: transparent;
  ${props => props.active ? 'color: #00dada;' : 'color: white;'}
  ${props => props.active ? 'border: 2px solid #00dada;' : 'border: 2px solid black;'}

  img{
    margin-left: 3px;
    width: 15px;
    height: 15px;
  }
`

export default function TypesFiltering(props){
  const {type, state, onClickFunction, id} = props;
  return(
    <TypeContainer active={state} onClick={onClickFunction} id={id}>
      {type}
    </TypeContainer>
  )
}