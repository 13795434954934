import React from "react"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Pie } from "react-chartjs-2";
import styled from "styled-components"
import tw from "twin.macro";
import { secondaryBackground, secondaryText } from "../const";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const PageContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
      mt-5
  `}
`

const Container = styled.div`
  width: 230px;
  height: 230px;
  position: relative;
`

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .euro{
    font-size: 0.9em;
    opacity: 0.9;
    margin-left: 3px;
  }

  .doughnutTitle{
    color: #00dada;
    font-size: 16px;
  }

  .value{
    color: white;
    font-size: 24px;
  }
  
  z-index: -1;
`


export default function HomePageChart(props){
  const colors = ['#00dada', '#666666'];
  const {data, title} = props;

  const dataP = {
    labels: data.labels,
    datasets: [
      {
        data: data.data,
        backgroundColor: colors,
        borderWidth: 10,
        borderColor: '#181818',
        hoverOffset: 10,
        borderRadius: 5
      }
    ]
  };

  const options = {
    cutout: '65%',
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      }
    }
  };


  return(
    <PageContainer>
      <Container>
        <Doughnut
          data={dataP}
          options = {options}
        />
        <TextContainer>
          <span className="doughnutTitle">{title}</span>
          <span className="value"><b>{data.data[0]}</b><span className="euro">€</span></span>
        </TextContainer>
      </Container>
    </PageContainer>
  )
}