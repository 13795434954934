import './SmallDonut.css'
import styled from 'styled-components';
import tw from 'twin.macro';
import React from 'react';
import info_icon from "../icons/info_icon.png"

const StyledCircle = styled.circle`
  @keyframes fillCircle{
    0%{
      stroke-dasharray: 230;
    }
    100%{
      stroke-dasharray: 405;
    }
  }
  fill: none;
  stroke: url(#circle${props => props.circleId});
  stroke-width: 20px;
  stroke-dashoffset: ${props => props.offset};
  animation: fillCircle 2s linear forwards;
`

const TextContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      justify-center
      text-white
      relative
  `}
  opacity: 1;
  rotate: +70deg;

  .euro{
    opacity: 0.9;
    font-size: 0.8em;
  }
  .title{
    font-weight: 400;
    opacity: 0.7;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .title img{
    width: 15px;
    height: 15px;
    margin-left: 4px;
  }

  .value{
    font-weight: 600;
    font-size: 22px;
    padding: 2px 0px;
    opacity: 0.9;
  }

  .subValue{
    font-weight: 400;
    opacity: 0.7;
    font-size: 13px;

  .positiveValue{
    color: #61DE70;
  }
  }
`

const InfoContainer = styled.div`
  ${tw`
      absolute
      text-white
  `}
  rotate: +70deg;
  border-radius: 10px;
  width: 90%;
  top: 36px;
  left: 5px;
  font-size: 8px;
  background-color: rgba(0,0,0,0.9);
  padding: 10px;
  z-index: 1000;
  text-align: justify;
  text-justify: inter-word;
`

export default function SmallDonut(props){
  const {value, title, subValue, color1, color2, infoText, circleNum} = props;
  const [showInfo, setShow] = React.useState(false);
  
  const [offset, setOffset] = React.useState(405);

  const percent = Math.min(1, value/subValue)
  React.useEffect( () => {
    setOffset(405 - 405*(percent))
  }, [percent]);

  function changeShow(){
    setShow(!showInfo);
  }

  return(
    <div className="smskill" onClick={changeShow} >
      <div className="smouter">
        <div className="sminner">
          <TextContainer>
            <div className="title">
              {title}
              <img src={info_icon}/>
            </div>
            <div className="value">{value}<span className="euro">€</span></div>
            <div className="subValue">
              { value/subValue >= 1 ? 
                  <div className="positiveValue">
                    %{((value/subValue)*100).toFixed(0)}
                  </div> :
                  `${subValue}€`
              }
            </div>
          </TextContainer>
        </div>
      </div>
      {showInfo &&
              <InfoContainer>
                {infoText}
              </InfoContainer>
            }
      <svg height="150px" width="150px"className="smsvgCircle">
        <defs>
          <linearGradient id={`circle${circleNum}`}>
            <stop offset="00%" stopColor={color1}/>
            <stop offset="100%" stopColor={color2}/>
          </linearGradient>
        </defs>
        <StyledCircle circleId={circleNum} offset={offset} cx="75" cy="75" r="65" strokeLinecap="round" />
      </svg>
    </div>
  )
}