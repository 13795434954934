import React from "react";
import {mediumInput, longInput} from "../const.js"
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "./FormField.jsx";
import MainButton from "./MainButton.jsx";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import ErrorMessage from "./ErrorMessage.jsx";
import { FormRow } from "../styledElements.js";
import { useNavigate } from "react-router-dom";
import CorrectPopUp from "./CorrectPopUp.jsx";
import ErrorPopUp from "./ErrorPopUp.jsx";
import OTPInput from "./OTPInput.jsx";


const FormContainer = styled.div`
  ${tw`
    p-7
    justify-items-center
    flex
    flex-col
  `}
`

const RegistrationText = styled.span`
  ${tw`
      text-white  
      px-4
  `}
  width: ${longInput};
  font-size: 35px;
  font-weight: 600;

  .colored{
    color: #00dada;
  }
`

const VERIFY_OTP = gql`
  query verifyOtpRegistration($email: String!, $otp: String!){
    verifyOtpRegistration(email: $email, otp: $otp)
  }
`

const SENDMAIL = gql`
query verifyOtpRegistration($email: String!, $otp: String!){
  verifyOtpRegistration(email: $email, otp: $otp)
}
`

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.mt ? `${props.mt}px` : '150px')};
`
const SendAgain = styled.button`
  ${tw`
      px-8
      py-2
      rounded-xl
      text-white
      mt-4
  `}
  font-size: 15px;
  background: ${props => props.active ? '#00dada' : 'transparent'};
  ${props => !props.active && 'border: 1px solid #00dada;'}
`

const SendText = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      mt-8
      mb-4
      font-medium
  `}
  color: rgba(255,255,255,0.8);
  font-size: 14px;
`
const MarginDiv = styled.div`
  margin-top: 10px;
`


export default function RegistrationForm(){

  const navigate = useNavigate();
  //component states

  //keeps the user information to subsequently register him
  const [userInfo, setUserInfo] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    active: false
  });

  //keeps information on the validity of the form fields
  const [validation, setValidity] = React.useState({
    validEmail: true,
    validPassword: true,
    samePassword: true
  });

  
  const SIGNUP = gql`
    mutation createUser($args: NewUserArgs!) {
      createUser(args: $args)
    }
  `;

  const [createUser, {loading, error, data}] = useMutation(SIGNUP, {
    onCompleted: () => {
      setCorrect(true);
      setTimeout( () => {
        setPhase("otp")
      }, 1000);
    },
    onError: () => {
      setUserExistsText(error.message);
      setUserExists(true);
      setUserInfo( prevUser => {
        return({
          ...prevUser,
          email: ""
        })
      })
    }
  });
  const [showCorrect, setCorrect] = React.useState(false);
  const [userExists, setUserExists] = React.useState(false);
  const [userExistsText, setUserExistsText] = React.useState("");


  function handleInput(event){
    const {name, value} = event.target;
    setUserInfo( prevUser => {
      return(
        {
          ...prevUser,
          [name]: value
        }
      )
    });
  };


  function checkPassword(str){
    const hasNumber = /\d/.test(str);
    const hasLetter = /[a-zA-Z]/.test(str);
    const hasSpecialSymbol = /[^a-zA-Z0-9]/.test(str);
    const minLength = str.length >= 8;

    return hasNumber && hasLetter && hasSpecialSymbol && minLength;
  }

  async function handleSubmit(event){
    event.preventDefault();

    const newUser1 = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      password: userInfo.password
    }
    createUser({
      variables: {
        args: newUser1,
      }
    })
  }



  //useEffect section, keeeping the UI updated


  //checks the validity of the email with a regular expression
  React.useEffect( () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (userInfo.email === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          validEmail: true
        })
      });
    }
    else{
      setValidity( prevVal => {
        return ({
          ...prevVal,
          validEmail: emailRegex.test(userInfo.email)
        })
      });
    }
    setEmail(userInfo.email);
  }, [userInfo.email]);


  //checks if the password has all the required characters
  React.useEffect( () => {
    if (userInfo.password === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: checkPassword(userInfo.password)
        })
      });
    }
  }, [userInfo.password]);


  //checks if the two passwords match
  React.useEffect( () => {
    if (userInfo.confirmPassword === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: userInfo.password === userInfo.confirmPassword
        })
      });
    }
  }, [userInfo.confirmPassword])


  //checks if it is okay for the user to register
  React.useEffect( () => {
    if(!userInfo.firstName || !userInfo.lastName|| !userInfo.email || !userInfo.password || !userInfo.confirmPassword){
      setUserInfo( prevUser => {
        return({
          ...prevUser,
          "active": false 
        })
      })
    }
    else{
      setUserInfo( prevUser => {
        return({
          ...prevUser,
          "active": true
        })
      })
    }
  }, [userInfo.firstName, userInfo.lastName, userInfo.email, userInfo.password, userInfo.confirmPassword]);


  const [phase, setPhase] = React.useState("registration");
  const [otpComplete, setOtpComplete] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const [otpError, setOtpError] = React.useState(false);
  const [otpCorrect, setOtpCorrect] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [emailCorrect, setEmailCorrect] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [sendEmail, {loading: loadingEmail, data: dataEmail}] = useLazyQuery(SENDMAIL, {
    onError: () => {
      setEmail('');
      setEmailError(true);
      setTimeout( () => {
        setEmailError(false);
      }, 1500)
    },
    onCompleted: () => {
      setEmailCorrect(true);
      setTimeout( () => {
        setEmailCorrect(false);
        setPhase("otp")
      }, 1500)
    }
});

  const [verifyOtp, {loading: loadingOTP, data: dataOTP}] = useLazyQuery(VERIFY_OTP, {
    onError: () => {
      setOtpError(true);
      setTimeout( () => {
        setOtpError(false);
      }, 1500)
    },
    onCompleted: () => {
      setOtpCorrect(true);
      setTimeout( () => {
        setOtpCorrect(false);
        navigate('/Login')
      }, 1500)
    }
  })

  function handleOTP(event){
    event.preventDefault();

    verifyOtp({
      variables:{
        email: email,
        otp: otp
      }
    })
  }

  const [timeout, setTimer] = React.useState(60);

  React.useEffect( () => {
    if (timeout > 0 && phase === "otp"){
      const countdown = setInterval( () => {
        setTimer((prevSeconds) => prevSeconds - 1);
      }, 1000)
      return () => clearInterval(countdown);
    }    
  }, [timeout, phase]);

  React.useEffect( () => {
    setOtpComplete(otp.length === 6);
  }, [otp])


  return(
    <div>
    {phase == "registration" && <form onSubmit={handleSubmit}>
      <RegistrationText>
          Sign <span className="colored">in</span>
        </RegistrationText>
      <FormContainer>
        <FormRow>
          <FormField width={mediumInput} name = "Nome" pHolder = "Marco" inputName = "firstName" value = {userInfo.firstName} handler={handleInput}></FormField>
          <FormField width = {mediumInput} name = "Cognome" pHolder = "Rossi" inputName = "lastName" value = {userInfo.lastName} handler={handleInput}></FormField>
        </FormRow>
        <FormField width = {longInput} name = "Email" pHolder = "marco.rossi@gmail.com" inputName = "email" value = {userInfo.email} handler={handleInput}></FormField>
        {!validation.validEmail && <ErrorMessage text="La mail non ha un formato valido!" />}
        <FormField width = {longInput} name = "Password" pHolder = "********" inputName = "password" value = {userInfo.password} handler={handleInput} type="password"></FormField>
        {!validation.validPassword && <ErrorMessage text={"La password deve contentere:"} ul={true} ulOptions={["1 numero", "1 carattere speciale", "1 lettera maiuscola", "almeno 8 caratteri"]}/>}
        <FormField width = {longInput} name = "Conferma password" pHolder = "********" inputName = "confirmPassword" value = {userInfo.confirmPassowrd} handler={handleInput} type="password"></FormField>
        {!validation.samePassword && <ErrorMessage text="Le password non combaciano" />}
        <MainButton text="Registrati" paddingTop = "5px" active={userInfo.active && validation.samePassword && validation.validEmail && validation.validPassword} type="submit"></MainButton>
        {showCorrect && 
          <CorrectPopUp text="La registrazione è avvenuta con successo" />
        }
        {userExists &&
          <ErrorPopUp text={userExistsText} />
        }
      </FormContainer>
    </form>}
    
    {phase == "otp" && 
    <PageContainer mt={'20'} onSubmit={handleOTP}>
      <OTPInput otp={otp} setOtp={setOtp}/>
      <MainButton
        type="submit"
        text="Verifica"
        active={otpComplete}
      />
      <SendText onClick={handleSubmit}>
        Non hai ricevuto l'email? Prova tra {timeout}
        <SendAgain disabled={timeout} active={timeout === 0}>Invia nuovamente</SendAgain>
      </SendText>
      <MarginDiv />
      {otpError && <ErrorPopUp text="Otp non corretto"/>}
      {otpCorrect && <CorrectPopUp text="Otp corretto, account creato!"/>}
      {emailCorrect && <CorrectPopUp text="Email inviata"/>}
      {emailError && <ErrorPopUp text="Email non inviata"/>}
    </PageContainer>}
    </div>
  )
}