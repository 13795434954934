import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "./FormField.jsx";
import MainButton from "./MainButton.jsx";
import {gql, useLazyQuery} from '@apollo/client'
import { useNavigate } from "react-router-dom";
import {loginInput} from "../const.js"
import ErrorMessage from "./ErrorMessage.jsx";
import ErrorPopUp from "./ErrorPopUp.jsx";
import { FormContainer, TextContainer, LinkContainer } from "../styledElements.js";
import CorrectPopUp from "./CorrectPopUp.jsx";
import cookies from "../help/cookie.js";
import lock from "../icons/sm_lock.png"
import email from "../icons/sm_email.png"


const ForgotDiv = styled.div`
  ${tw`
      flex
      justify-end
  `}
  width: ${loginInput};
  margin-bottom: 25px;
`

const ForgotText = styled.a`
  ${tw`
      text-white
      font-medium
  `}
  font-weight: 400;
  margin-top: -20px;
  font-size: 14px;
`

export default function LoginForm(){
  const navigate = useNavigate();

  const [user, setUser] = React.useState({
    email: "",
    password: "",
    active: false
  });

  const LOGIN = gql`
    query($email: String!, $password: String!) {
      login(email: $email, password: $password)
    }
  `;

  const [showError, setError] = React.useState(false);
  const [validEmail, setValidity] = React.useState(true);
  const [isLogged, setLogged] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("Email e/o password sono errati");

  const [getResults, {loading, data}] = useLazyQuery(LOGIN, {
    onError: (e) => {
      setErrorMessage(e.message);
      setError(true);
      setUser(prevUser => {
        return{
          ...prevUser,
          password: ""
        };
      });
      setTimeout( () => {
        setError(false);
      }, 2000)
    }
  });

  function handleInput(event){
    const {name, value} = event.target;
    setUser( prevUser => {
      return(
        {
          ...prevUser,
          [name]: value,
        }
      )
    });
    if (showError){
      setError(false);
    }
  };

  React.useEffect( () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (user.email === ""){
      setValidity(true);
    }
    else{
      setValidity(emailRegex.test(user.email));
    }
  }, [user.email]);

  React.useEffect(() => {
    if(user.password === "" || user.email === ""){
      setUser( prevUser => {
        return({
          ...prevUser,
          "active": false 
        })
      })
    }
    else{
      setUser( prevUser => {
        return({
          ...prevUser,
          "active": true
        })
      })
    }
  }, [user.password, user.email]);

  function handleSubmit(event){
    event.preventDefault();
    
    try{
      getResults({
        variables: {
          email: user.email,
          password: user.password
        }
      });
    }
    catch(error){
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (data && data.login) {
      const token = data.login;
      cookies.set('accessToken', token, { path: '/' });
      setLogged(true);
      setTimeout( () => {
        navigate(`/home`);
      }, 1500)
    }
  }, [data]);

  return(
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FormField  
          width={loginInput} 
          name = "Email" 
          pHolder = "Username" 
          inputName = "email" 
          value = {user.email} 
          handler={handleInput}
          fontDim="20px"
          inputDim="16px"
          icon={email}
        >
        </FormField>
        {!validEmail && <ErrorMessage text="L'email non ha un formato corretto" />}
        <FormField  
          width={loginInput} 
          name = "Password" 
          pHolder = "*******" 
          inputName = "password" 
          value = {user.password} 
          handler={handleInput}
          fontDim="20px"
          inputDim="16px"
          type="password"
          icon={lock}
        >
          
        </FormField>
        <ForgotDiv>
          <ForgotText onClick={() => navigate("/RecuperoPassword")}>
            Password dimenticata?
          </ForgotText>
        </ForgotDiv> 
        <ForgotDiv>
          <ForgotText onClick={() => navigate("/Verifica")}>
            Account non verificato?
          </ForgotText>
        </ForgotDiv> 
        <MainButton
          type="submit"
          text="Accedi"
          active={user.active}
          paddingTop="-20px"
        >
        </MainButton>
        <TextContainer>
          Non hai un account?
          <LinkContainer onClick={() => navigate("/Registrati")}>
            Registrati!
          </LinkContainer>
        </TextContainer>
        {isLogged &&
        <CorrectPopUp text="Accesso effettuato con successo"/>
        }
        {showError && <ErrorPopUp text={errorMessage} />}

      </FormContainer>
    </form>
  )
}