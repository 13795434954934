// GlobalStateContext.js
import React, { createContext, useContext, useReducer } from 'react';

const GlobalStateContext = createContext();

const initialState = {
  dataUpdated: {
    homeData: false,
    walletData: false,
    warehouseData: false,
    dataData: false,
    goalData: false,
    taxData: false,
    sellPageData: false,
    newItemData: false
  },
  itemToSell : {
    itemInfo: null,
    index: 0
  }
};

const actionTypes = {
  TOGGLE_DATA_UPDATE: 'TOGGLE_DATA_UPDATE',
  SET_ALL_DATA_UPDATED: 'SET_ALL_DATA_UPDATED',
};

const sellActionTypes = {
  SET_ITEM_TO_SELL: 'SET_ITEM_TO_SELL',
  RESET_ITEM_INFO: 'RESET_ITEM_INFO'
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DATA_UPDATE:
      return {
        ...state,
        dataUpdated: {
          ...state.dataUpdated,
          [action.payload]: false,
        },
      };
    case actionTypes.SET_ALL_DATA_UPDATED:
      return {
        ...state,
        dataUpdated: Object.fromEntries(
          Object.keys(state.dataUpdated).map((key) => [key, true])
        ),
      };
    case sellActionTypes.SET_ITEM_TO_SELL:
      return {
        ...state,
        itemToSell: {
          itemInfo: {...action.payload.itemInfo},
          index: 1
        }
      };
    case sellActionTypes.RESET_ITEM_INFO:
      return {
        ...state,
        itemToSell: {
          itemInfo: null,
          index: 0
        }
      };
    default:
      return state;
  }
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};

export { GlobalStateProvider, useGlobalState, actionTypes, sellActionTypes };
