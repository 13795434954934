import styled from "styled-components"
import tw from "twin.macro";
import lock_icon from "../icons/lock_icon.png"
import logout_icon from "../icons/logout_icon.png"
import edit_icon from "../icons/edit_icon.png"
import coin_icon from "../icons/coin_icon.png"
import document_icon from "../icons/document_icon.png"
import { useNavigate } from "react-router-dom";
import cookies from "../help/cookie";

const OptionsContainer = styled.div`
  ${tw`
      flex
  `}
  flex-direction: column;
  margin-top: 5px;
`

const Option = styled.div`
  ${tw`
      flex
      flex-row
      w-full
  `}
  align-items: center;
  font-size: 16px;
  background: #242424;
  height: 55px;
  border-bottom: 2px solid #181818;

  color: white;
  font-weight: 400;

  img{
    width: 25px;
    margin-right: 5%;
    margin-left: 20px;
  }
`

const SmallTitle = styled.div`
  ${tw`
      text-white
      font-semibold
  `}
  margin-top: 15px;
  margin-left: 20px;
  font-size: 18px;
  margin-bottom: 15px;
  
`

export default function ProfileOptions(){
  const navigate = useNavigate();

  return(
    <OptionsContainer>
      <SmallTitle>
        Account
      </SmallTitle>
      <Option onClick={ () => {
        navigate('/profilo/modificaDati');
      }}>
        <img src={edit_icon}/>
        <span>Modifica dati personali</span>
      </Option>
      <Option onClick={ () => {
        navigate('/profilo/cambiaPassword');
      }}>
        <img src={lock_icon}/>
        <p>Cambia password</p>
      </Option>
      <SmallTitle>
        Abbonamento
      </SmallTitle>
      <Option onClick={ () => {
        navigate('/profilo/gestionePiano');
      }}>
        <img src={coin_icon}/>
        <p>Gestisci il tuo piano</p>
      </Option>
      <SmallTitle>
        Sessione
      </SmallTitle>
      <Option onClick={ () => {
        cookies.remove('accessToken');
        navigate('/');
      }}>
        <img src={logout_icon}/>
        <p>Esci</p>
      </Option>
    </OptionsContainer>
  )

  
}