import tw from "twin.macro";
import styled from "styled-components";
import tagIcon from "../icons/tag.png";

import deleteIcon from "../icons/delete.png" 
import { mainPurple, secondaryBackground } from "../const";

const TagButton = styled.button`
  @keyframes grow{
    25%{
      transform: scale(1.2);
      background: ${mainPurple};
    }
  }

  ${tw`
      flex
      justify-center
      items-center
      mx-2
  `}
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${props => props.bColor || 'white'};
  background: transparent;

  &:active {
    background-color: red;
    animation: grow 0.5s ease-out;
  }
  
  img {
    max-width: 15px;
    max-height: 15px;
  }
`

const TagRow = styled.div`
  ${tw`
      flex
      flex-row
      mb-2
      items-center
  `}
  
  img{
    width: 25px;
    height: 25px;
  }
`

const TagContainer = styled.div`
  ${tw`
      flex
      text-white
      mx-2
      px-2
      items-center
  `}
  font-size: 15px;
  border-radius: 10px;
  border-bottom: 1px solid ${mainPurple};
  border-right: 1px solid ${mainPurple};
  border-left: 1px solid ${mainPurple};
  height: 30px;
  width: 200px;
  background-color: transparent;
`

const TagsContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      px-3
      text-white
      font-medium
      mb-5
  `}

  
  p{
    margin-bottom: 5px;
  }
`
export default function TagList(props){
  const {tags, func} = props;

  return(
    <TagsContainer>
      <p>Tags aggiunti</p>
      {tags.map( (tag, tagIndex) => (
        <TagRow>
          <img src={tagIcon} />
          <TagContainer>
            {tag}
          </TagContainer>
          <TagButton bColor='red' onClick={() => func(tagIndex)}>
            <img src={deleteIcon} />
          </TagButton>
        </TagRow>
      ))}
    </TagsContainer>
  )
}