import LoginPage from './main_pages/LoginPage';
import {Route, Routes} from 'react-router-dom';
import RegistrationPage from './main_pages/RegistrationPage';
import WalletPage from './main_pages/WalletPage';
import NewItemPage from './main_pages/NewItemPage';
import HomePage from './main_pages/HomePage';
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/zh-cn.js'
import ProfilePage from './main_pages/ProfilePage';
import RecoveryPage from './main_pages/RecoveryPage';
import ChangeInfoPage from './main_pages/ChangeInfoPage';
import ChangePass from './main_pages/ChangePass';
import ObjectivesPage from './main_pages/ObjectivesPage';
import WarehousePage from './main_pages/WarehousePage';
import DataPage from './main_pages/DataPage';
import TaxesPage from './main_pages/TaxesPage';
import GuidePage from './main_pages/GuidePage';
import DocumentsPage from './main_pages/DocumentsPage';
import PaymentPage from './main_pages/PaymentPage';
import VerificationPage from './main_pages/VerificationPage';
import ExpensesPage from './main_pages/ExpensesPage';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-cn.js">
      <Routes>
      <Route 
          path="/Home"
          element={<HomePage/>}
        />
        <Route 
          path="/"
          element={
            <LoginPage />
          }
        />
        <Route
          path="/Goals"
          element={
            <ObjectivesPage />
          }
        />
        <Route 
          path="/Login"
          element={
            <div>
              <LoginPage />
            </div>
          }
        />
        <Route
          path="/registrati"
          element={
            <RegistrationPage />
          }
        />
        <Route 
          path="/RecuperoPassword"
          element={
            <div>
              <RecoveryPage />
            </div>
          }
        />
        <Route 
          path="/Verifica"
          element={
            <div>
              <VerificationPage />
            </div>
          }
        />
        <Route 
          path="/contabilità"
          element={
            <WalletPage />
          }
        />
        <Route
          path="/magazzino"
          element={
            <WarehousePage />
          }
        />
        <Route
          path="/spese"
          element={
            <ExpensesPage />
          }
        />
        <Route 
          path="/profilo"
          element={
            <ProfilePage />
          }
        />
            <Route 
              path="/profilo/modificaDati"
              element={
                <ChangeInfoPage />
              }
            />
            <Route 
              path="/profilo/cambiaPassword"
              element={
                <ChangePass />
              }
            />          
            <Route 
              path="/profilo/gestionePiano"
              element={
                <PaymentPage />
              }
            />
          <Route
          path="/nuovoOggetto/"
          element={
            <NewItemPage />
          }
        />
        <Route
          path="/data"
          element={
            <DataPage />
          }
        />
        <Route
          path="/tasse"
          element={
            <TaxesPage />
          }
        />
        <Route
          path="/documenti"
          element={
            <DocumentsPage />
          }
        />
        <Route
          path="/guida"
          element={
            <GuidePage />
          }
        />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
