import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "../components/FormField";
import { longInput } from "../const";
import ErrorMessage from "../components/ErrorMessage";
import MainButton from "../components/MainButton";
import ErrorPopUp from "../components/ErrorPopUp";
import CorrectPopUp from "../components/CorrectPopUp";
import { gql, useMutation } from "@apollo/client";
import lock from "../icons/sm_lock.png"
import back_arrow from "../icons/back_arrow.png"
import { useNavigate } from "react-router-dom";

const Arrow = styled.div`
  width: 25px;
  height: 25px;
  z-index: 2;
`

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`
const TextContainer = styled.div`
    color: #efefef;
    font-size: 18px;
    font-weight: 500;
    width: ${longInput};
    margin-bottom: 20px;
`
const RecoveryText = styled.span`
  ${tw`
      text-white 
  `}
  margin-left: 15px;
  width: 100%;
  font-size: 25px;
  font-weight: 600;
`
const MarginDiv = styled.div`
  margin-top: 10px;
`

const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!){
    changePassword(password: $password)
  }
`

const RowDiv = styled.div`
    ${tw`
      flex
      items-center
    `}
    margin-top: 30px;
  margin-left: 20px;
`

export default function ChangePass(){
  const navigate = useNavigate();
  
  const [passInfo, setPassInfo] = React.useState({
    password: "",
    confirmPassword: ""
  })
  const [validation, setValidity] = React.useState({
    validPassword: true,
    samePassword: true
  });
  const [error, setError] = React.useState(false)

  const [changePassword, {loading: loadingPass, data: dataPass}] = useMutation(CHANGE_PASSWORD)

  React.useEffect( () => {
    if (passInfo.password === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: checkPassword(passInfo.password)
        })
      });
    }
  }, [passInfo.password]);


  //checks if the two passwords match
  React.useEffect( () => {
    if (passInfo.confirmPassword === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: passInfo.password === passInfo.confirmPassword
        })
      });
    }
  }, [passInfo.confirmPassword])

  function handleInput(event){
    const {name, value} = event.target;
    setPassInfo( prevUser => {
      return(
        {
          ...prevUser,
          [name]: value
        }
      )
    });
  };

  function checkPassword(str){
    const hasNumber = /\d/.test(str);
    const hasLetter = /[a-zA-Z]/.test(str);
    const hasSpecialSymbol = /[^a-zA-Z0-9]/.test(str);
    const minLength = str.length >= 8;

    return hasNumber && hasLetter && hasSpecialSymbol && minLength;
  }

  function handleNewPassword(event){
    event.preventDefault();

    try{
      changePassword({
        variables: {
          password: passInfo.password
        }
      });
      setPassInfo({
        password: "",
        confirmPassword: ""

      })
    }
    catch(error){
      setError(true)
      setPassInfo({
        password: "",
        confirmPassword: ""
      });
      setTimeout( () => {
        setError(false)
      }, 2000)
    }
  }
  return(
    <div>
      <RowDiv>
        <Arrow onClick={ () => {
          navigate('/profilo');
        }}>
          <img src={back_arrow} />
        </Arrow>
        <RecoveryText>
          Nuova password
        </RecoveryText>
      </RowDiv>
    <PageContainer onSubmit={handleNewPassword}>
      <TextContainer>
        Crea una nuova password e salva
      </TextContainer>
      <FormField icon={lock} width = {longInput} name = "Password" pHolder = "********" inputName = "password" value = {passInfo.password} handler={handleInput} type="password"></FormField>
      {!validation.validPassword && <ErrorMessage width = {longInput} left={true} text={"La password deve contentere:"} ul={true} ulOptions={["1 numero", "1 carattere speciale", "1 lettera maiuscola", "almeno 8 caratteri"]} />}
      <FormField icon={lock} width = {longInput} name = "Conferma password" pHolder = "********" inputName = "confirmPassword" value = {passInfo.confirmPassword} handler={handleInput} type="password"></FormField>
      {!validation.samePassword && <ErrorMessage width = {longInput} left={true} text="Le password non combaciano" />}
      <MainButton active={passInfo.password && validation.validPassword && passInfo.confirmPassword && validation.samePassword} disabled={passInfo.password && validation.validPassword && passInfo.confirmPassword && validation.samePassword} text="Salva" type="submit" />
      <MarginDiv />
      {(dataPass && dataPass.changePassword) && <CorrectPopUp text="Password modificata con successo" />}
      {error && <ErrorPopUp text="password non salvata" />}
    </PageContainer>
    </div>
  )
}