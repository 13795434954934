import React from "react"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Pie } from "react-chartjs-2";
import styled from 'styled-components'
import tw from "twin.macro";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const Container = styled.div`
  ${tw`
      mt-6
      w-full
      flex
      flex-col
      justify-center
      items-center
  `}
`

const ChartTitle = styled.p`
  ${tw`
      font-semibold
  `}
  color: rgba(180,201,225, 1);
  font-size: 20px;
  margin-bottom: 10px;
`
const PieContainer = styled.div`
  ${tw`
      flex
  `}
  width: 250px;
  height: 250px;
`

export default function PieChart(props){
  const colors = ['#33ccff', '#6600cc', '#967bb6','#708090','#aa0000' ,'#008080'];
  const {labels, data, title, total} = props;

  var bColors = [];

  for (let i = 0; i < data.length; i++){
    bColors.push(colors[i]);
  }


  const labelsPercentage = labels.map( (label, index) => label + ` ${Math.round(data[index] / total * 100)}%`)

  const dataP = {
    labels: labelsPercentage,
    datasets: [
      {
        data: data,
        backgroundColor: bColors,
        borderWidth: 5,
        borderColor: '#001818',
        hoverOffset: 10,
        borderRadius: 5
      }
    ]
  };

  const options = {
    cutout: '65%',
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: 'rgba(255,255,255,0.8)',
          boxWidth: 20
        }
      },
      datalabels: {
        display: false,
      }
    }
  };


  return(
    <Container>
      <ChartTitle>
        {title}
      </ChartTitle>
      <PieContainer>
        <Doughnut
          className="pieC"
          data={dataP}
          options = {options}
        />
      </PieContainer>
    </Container>
  )
}